import React from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField, DialogActions, Button, FormControl, InputLabel, Select, Input, Chip, MenuItem
} from "@material-ui/core";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          device: {
            id: null,
            name: '',
            key: '',
            update: 0,
            groups: []
          }
        };
      }
    handleChangeGroups = event => {
      this.setState({
        device: {
          ...this.state.device,
          groups: event.target.value
        }
      });
    };
    
    componentDidUpdate(prevProps) {
      if ((this.props.device !== prevProps.device) && (this.props.device !== null)) {
        this.setState({
          device: {
            ...this.props.device,
            groups: this.props.device.groups.map(group => Number(group.id))
          }
        })
      }
    }

    render(){
        const { classes, title, submitAction, cancelAction, open, groups } = this.props;
        const { device } = this.state;
        return (
            <React.Fragment>
            <Dialog open={ open } onClose={() => cancelAction()}>
                <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="label"
                    label="Название"
                    onChange={(e)=>{device.name = e.target.value; this.forceUpdate()}}
                    value={ device.name }
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="label"
                    label="Ключ"
                    onChange={(e)=>{device.key = e.target.value; this.forceUpdate()}}
                    value={device.key}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="label"
                    label="Частота обновления"
                    onChange={(e)=>{ device.update = e.target.value; this.forceUpdate()}}
                    value={device.update}
                    fullWidth
                />
                <FormControl fullWidth={true} >
                <InputLabel id="groups-label">Группы</InputLabel>
                <Select
                  labelId="groups-label"
                  id="groups"
                  multiple
                  value={this.state.device.groups}
                  onChange={this.handleChangeGroups}
                  input={<Input fullWidth />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={Number(value)} 
                          label={groups[value].name} 
                          className={classes.chip} 
                          onDelete={() => {
                            this.setState({
                              device: {
                                ...this.state.device,
                                groups: this.state.device.groups.filter( id => id !== Number(value))
                              }
                            })}
                          }/>
                      ))}
                    </div>
                  )}
                >
                  
                  {Object.keys(groups).map((key) => (
                      <MenuItem dense={true} key={ Number(key) } value={ Number(key) } >
                        {groups[key].name}
                      </MenuItem>
                    ))
                  } 
                </Select>
              </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{ cancelAction() }} color="primary">
                    Отмена
                </Button>
                <Button onClick={()=>{ submitAction(device) }} color="primary">
                    Сохранить
                </Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
        );
    }
};

const styles = theme => ({
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing.unit * 3
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});


export default withStyles(styles, { withTheme: true })(Form);
