import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { loadSavedSession } from '../store/actions/Auth';

import AppView from './App';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
    }), { loadSavedSession }
  )
)(AppView);