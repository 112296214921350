import React from 'react';
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
  Input,
  Divider,
  ExpansionPanelActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import {withStyles} from '@material-ui/styles';
import {DropzoneArea} from 'material-ui-dropzone'

const Transition = React.forwardRef (function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ComandsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scatch: null
    };
  }

  handleChangeScatch(files){
    this.setState({
        scatch: (files.length>0) ? files[0]:null
    });
  }

  render () {
    const {
      classes,
      idDevice,
      open,
      closeAction,
      scatchUpload,
      sendCommand
    } = this.props;
    
    const {
      scatch
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
              style={{color: '#ffffff'}}
            >
              Команды
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                closeAction();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Button
          onClick={() => {
            sendCommand (idDevice, {command: 'changeRelais'});
          }}
        >
          Реле
        </Button>
        
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>Обновление</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading} />
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <DropzoneArea 
              acceptedFiles={['.bin']}
              onChange={this.handleChangeScatch.bind(this)}
              filesLimit={1}
              dropzoneText={"Загрузите bin файл с обновлением..."}
              showFileNames={true}
              showAlerts={false}
            />
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button
              disabled={scatch===null}
              size="small"
              color="primary"
              onClick={() => {
                scatchUpload(scatch, idDevice);
                sendCommand (idDevice, {command: 'update'});
              }}
            >
              Обновить
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </Dialog>
    );
  }
}

const styles = theme => ({
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'fixed',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing.unit * 2,
    flex: 1,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem (15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem (15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit * 2,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default withStyles (styles, {withTheme: true}) (ComandsForm);
