import { combineReducers } from 'redux';

import layout from './reducers/Layout';
import auth from './reducers/Auth';
import devices from './reducers/Devices'
import devicesParams from './reducers/DevicesParams'
import users from './reducers/Users'
import permissions from './reducers/Permissions'
import units from './reducers/Units'
import devices_groups from './reducers/DevicesGroups'
import cameras from './reducers/Cameras'

export default combineReducers({
  layout,
  auth,
  devices,
  users,
  permissions,
  devicesParams,
  units,
  devices_groups,
  cameras
});