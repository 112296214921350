import React from 'react';
import {
  Grid,
  withStyles,
  Paper,
  LinearProgress,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { Player, ControlBar, BigPlayButton } from 'video-react';
import HLSSource from '../../../components/HLS/HLSSource';
import "video-react/dist/video-react.css";
import Axios from 'axios';

class ListItem extends React.Component {
  constructor (props) {
    super (props);
  }
  
  render () {
    const { classes, item } = this.props;
    
    return (
      <React.Fragment>
        <Grid className={classes.paper} item lg={3} md={6} sm={12} xs={12}>
            <Player>
                <HLSSource
                    isVideoChild
                    src={Axios.defaults.baseURL+"/api/cameras/"+item.id+"/stream/index.m3u8"}
                />
                <BigPlayButton position="center" />
                <ControlBar disabled />
            </Player>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  
});

export default withStyles (styles, {withTheme: true}) (ListItem);
