import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";

import DirectoryView from "./Directory";
import { getUnitsList, addUnit, editUnit, deleteUnit } from "../../store/actions/Units";
import { getDevicesGroupList, addDevicesGroup, editDevicesGroup, deleteDevicesGroup } from "../../store/actions/DevicesGroups";

export default compose(
  connect(
    state => ({
      groups: state.devices_groups.list,
      isLoadingDevicesGroups: state.devices_groups.isLoading,
      pageDevicesGroups: state.devices_groups.page,
      totalPagesDevicesGroups: state.devices_groups.total_pages,
      perPageDevicesGroups: state.devices_groups.per_page,

      units: state.units.list,
      isLoadingUnits: state.units.isLoading,
      pageUnits: state.units.page,
      totalPagesUnits: state.units.total_pages,
      perPageUnits: state.units.per_page,

      permissions: state.auth.permissions.map(permission => permission.name)
    }),{
      getUnitsList, addUnit, editUnit, deleteUnit,
      getDevicesGroupList, addDevicesGroup, editDevicesGroup, deleteDevicesGroup,
    }
  ),
  withState ('tabIndex', 'setTabIndex', 1),

  withState ('openDevicesGroupForm', 'setOpenDevicesGroupForm', false),
  withState ('devicesGroupFormType', 'setDevicesGroupFormType', 'add'), //add edit
  withState ('currentDevicesGroup', 'setCurrentDevicesGroup', null),

  withState ('openUnitForm', 'setOpenUnitForm', false),
  withState ('unitFormType', 'setUnitFormType', 'add'), //add edit
  withState ('currentUnit', 'setCurrentUnit', null),

  withState ('openConfirmationDialog', 'setOpenConfirmationDialog', false),
  withState ('deletedItemId', 'setDeletedItemId', 0), 
  withState ('deletedType', 'setDeletedType', 'unit'), //unit group
  lifecycle({
    componentDidMount() {
      this.props.getUnitsList();
      this.props.getDevicesGroupList();
    }
  })
)(DirectoryView);