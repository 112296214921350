import axios from 'axios';
import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_ERROR,
  LOGIN_USER,
  SIGN_OUT_SUCCESS
} from '../constants/Auth';

export const signOut = () => dispatch => {
  axios.get('/api/auth/logout').then(res => {
    dispatch({type: SIGN_OUT_SUCCESS});
  })
  axios.defaults.headers.common['Authorization'] = "";
  localStorage.removeItem("auth");
  window.location.href = '/login'
};

export const loginUser = (login, password) => dispatch => {
  dispatch({type: START_LOGIN});

  if (!!login && !!password) {
    axios.post('/api/auth/login', {
        email: login,
        password: password
      }
    ).then(res => {
        localStorage.setItem("auth", JSON.stringify({token_type: res.data.token_type, access_token: res.data.access_token}));
        axios.defaults.headers.common['Authorization'] = res.data.token_type+" "+res.data.access_token;
        dispatch({type: LOGIN_SUCCESS, payload: res.data});
        window.location.href = '/'
      }).catch(function (error) {
        dispatch({type: LOGIN_FAILURE});
    });
  } else {
    dispatch({type: LOGIN_FAILURE});
  }
};

export const loadSavedSession = () => dispatch => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';

    if(localStorage.getItem('auth')){
      let auth = JSON.parse(localStorage.getItem('auth'));
      let token = auth.token_type+" "+auth.access_token;
      axios.defaults.headers.common['Authorization'] = token;

      axios.get('/api/auth/user').then(res => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: Object.assign({}, res.data, {token_type: auth.token_type, access_token: auth.access_token})
          });
        }).catch(function (error) {
          dispatch({ type: LOGIN_FAILURE });
          localStorage.removeItem("auth");
        });
    } else {
      dispatch({ type: LOGIN_FAILURE });
    }
}