import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";

import View from "./Components";
import { getCamerasList, createCamera } from "../../store/actions/Cameras";


export default compose(
  connect(
    state => ({
      isLoading: state.cameras.isLoading,
      list: state.cameras.list,
      
      permissions: state.auth.permissions.map(permission => permission.name),
    }),{
        getCamerasList, createCamera
    }
  ),
  withState ('openSpeedDial', 'setOpenSpeedDial', false),
  withState ('openForm', 'setOpenForm', false),
  withState ('formType', 'setFormType', 'add'),
  withState ('camera', 'setCamera', {
    id: null,
    name: '',
    description: '',
    stream: '',
    substream: '',
    public: false
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCamerasList();
    }
  })
)(View);