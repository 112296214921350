import axios from 'axios';
import Echo from "laravel-echo"
import { toast } from 'react-toastify';
import {
  GET_PARAMS_DATA_REQUEST,
  GET_PARAMS_DATA_SUCCESS,
  GET_PARAMS_DATA_FAILURE
} from '../constants/DevicesParams';

window.io = require('socket.io-client');

export const initialState = {
  page: 1,
  deviceid: null,
  list: [],
  params: {},
  table: [],
  total: 0,
  isLoading: false,
  error: null
};

export default function DevicesParamsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARAMS_DATA_SUCCESS:
      return {
        ...state,
        list: action.data,
        params: action.params,
        table: action.table,
        isLoading: false
      };
    case GET_PARAMS_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_PARAMS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
}
