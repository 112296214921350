import React from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  Grid,
  MenuItem,
  FormControlLabel,
  Switch,
  TextField, DialogActions, Button
} from "@material-ui/core";
import { SketchPicker } from "react-color";

class ParamForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          param: {
            id: null,
            label: '',
            color: '',
            classIcon: '',
            isHidden: false,
            id_unit: 0
          },
          displayColorPicker: false
        };
      }
    
    componentDidUpdate(prevProps) {
        if ((this.props.param !== prevProps.param) && (this.props.param !== null)) {
          this.setState({
            param: {
              ...this.props.param
            }
          })
        }
      }

    render(){
        const { classes, title, openForm, submitAction, cancelAction, units } = this.props;
        const { param, displayColorPicker } = this.state;
        const arrIcons = [
            "wi wi-na",
            "wi wi-thermometer",
            "wi wi-humidity",
            "wi wi-barometer",
            "wi wi-flood",
            "wi wi-celsius",
            "wi wi-thermometer-exterior"];
        
        return (
            <React.Fragment>
            <Dialog open={ openForm } onClose={() => cancelAction()}>
                <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="label"
                        label="Название"
                        onChange={(e)=>{param.label = e.target.value; this.forceUpdate()}}
                        value={ param.label }
                        fullWidth
                    />
                    <Grid container alignItems="left">
                        <Grid item xs>
                        Icon
                        </Grid>
                        <Grid item xs>
                        <Select
                            value={param.classIcon}
                            onChange={(e)=>{ param.classIcon = e.target.value; this.forceUpdate()}}
                            inputProps={{
                                id: 'classIcon',
                                }}
                        >
                            {arrIcons.map((icon, index) => 
                            <MenuItem value={icon}><i className={icon}></i></MenuItem>
                            )}        
                        </Select>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="left">
                        <Grid item xs>
                        Цвет
                        </Grid>
                        <Grid item xs>
                        <div className={classes.swatch } onClick={ () => {this.setState({ displayColorPicker: !displayColorPicker })} }>
                            <div className={classes.color } style={{background: param.color}}/>
                        </div>
                        { displayColorPicker ? 
                            <div className={classes.popover }>
                            <div className={classes.cover } onClick={ () => {this.setState({ displayColorPicker: !displayColorPicker })} }/>
                            <SketchPicker color={ param.color } onChange={ (color) => {param.color = color.hex; this.forceUpdate(); } } />
                            </div> : null }
                        </Grid>
                    </Grid>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={param.isHidden}
                          onChange={() => {
                            this.setState({ 
                              param: {
                                ...this.state.param,
                                isHidden: !this.state.param.isHidden
                              }
                           }
                          )}}
                          name="isHidden"
                          color="primary"
                        />
                      }
                      label="Скрытый"
                    />
                  <Grid container alignItems="left">
                        <Grid item xs>
                          Ед. измерения
                        </Grid>
                        <Grid item xs>
                        { units &&
                          <Select
                            value={param.id_unit}
                            onChange={(e)=>{ param.classIcon = e.target.value; this.forceUpdate()}}
                            inputProps={{
                                id: 'id_unit',
                                }}
                          >
                            {units.map((unit) => 
                              <MenuItem value={unit.id}>{ unit.name }</MenuItem>
                            )}        
                          </Select>
                        }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{ cancelAction() }} color="primary">
                    Отмена
                </Button>
                <Button onClick={()=>{ submitAction(this.state.param) }} color="primary">
                    Сохранить
                </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
        );
    }
};

const styles = theme => ({
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing.unit * 3
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'fixed',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }
});

export default withStyles(styles, { withTheme: true })(ParamForm);