import React from "react";
import ReactApexChart from "react-apexcharts";
import { withStyles } from "@material-ui/styles";

class Chart extends React.Component {
    render(){
        const { data, params } = this.props;
          
        return (
            <ReactApexChart options={ 
                {
                  chart: {
                    type: 'area',
                    height: 350,
                    stacked: true
                  },
                  colors: Object.keys(params).map(id => params[id].color),
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'straight'
                  },
                  fill: {
                    type: 'gradient',
                    gradient: {
                      opacityFrom: 0.6,
                      opacityTo: 0.8,
                    }
                  },
                  legend: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                  },
                  tooltip: {
                    x: {
                      show: true,
                      format: 'dd.MM.yy HH:mm'
                    }
                  },
                  xaxis: {
                    type: 'datetime',
                    labels: {
                      format: 'dd.MM.yy HH:mm',
                    }
                  },
                  noData: {
                    text: 'Нет данных для отображения',
                    align: 'center'
                  }
                }
              } series={ data } 
              type="area" height={350} />
              )}
            }
            
const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(Chart);
