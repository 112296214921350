import React from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent, 
  DialogActions, 
  Button
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

class DevicesGroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      group: {
        id: null,
        name: ''
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.group !== prevProps.group)  {
        if(this.props.group !== null){
            this.setState({
                group: this.props.group
            })
        } else {
            this.setState({
                group: {
                    id: null,
                    name: ''
                  }
            })
        }
    }
  }

  render(){
    const { classes, theme, openForm, nameForm, submitAction, cancelAction, group } = this.props;
    return (
      <React.Fragment>
     
      {openForm &&
        <Dialog open={openForm} onClose={() => cancelAction()}>
          <DialogTitle id="form-dialog-title">{nameForm}</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="name"
                label="Название"
                onChange={(e)=>{
                        this.setState({
                            group: {
                                ...this.state.group,
                                name: e.target.value
                            }
                        })
                    }
                }
                value={this.state.group.name}
                fullWidth
              />              
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{cancelAction()}} color="secondary">
                Отмена
              </Button>
              <Button onClick={()=>{submitAction(this.state.group);}} color="primary">
                Сохранить
              </Button>
            </DialogActions>
      </Dialog>
      }
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});

export default withStyles(styles, { withTheme: true })(DevicesGroupForm);
