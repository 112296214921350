import React from 'react';
import { withStyles, CssBaseline } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';

import Header from '../../components/Header/HeaderContainer';
import Sidebar from '../../components/Sidebar/SidebarContainer';
import Background from './img/back.jpg';

// pages
import Devices from '../devices/DevicesContainer';
import DevicesDetal from '../DevicesDetal/DevicesDetalContainer';
import Error from '../error/Error';
import Users from '../users/UsersContainer';
import AllIcons from '../devices/icons/all';
import Directory from '../Directory/DirectoryContainer';
import Cameras from '../../Pages/Cameras';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ classes, isSidebarOpened, toggleSidebar, ...props }) => (
  <div className={classes.root}>
    <CssBaseline />
    <BrowserRouter>
      <React.Fragment>
        <Header />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          className={ classes.toastsContainer }
        />
        <Sidebar />
        <div className={classnames(classes.content, { [classes.contentShift]: isSidebarOpened })}>
          <div className={classes.fakeToolbar} />
            <Switch>
              {props.Permissions.indexOf('devices-list') !== -1 ? 
                <Route exact path="/" component={Devices} /> : null}
              {props.Permissions.indexOf('devices-list') !== -1 ? 
                <Route path='/devices/:id' component={DevicesDetal}/> : null}
              {props.Permissions.indexOf('devices-list') !== -1 ? 
                <Route exact path="/devices" component={Devices} /> : null}
              {props.Permissions.indexOf('users-list') !== -1 ? 
                <Route exact path="/users" component={Users} /> : null }
              {props.Permissions.indexOf('cameras-list') !== -1 ? 
                <Route exact path="/cameras" component={Cameras} /> : null }
              <Route exact path="/directory" component={Directory} /> 
              <Route component={Error} />
            </Switch>
          </div>
      </React.Fragment>
    </BrowserRouter>
  </div>
);

const styles = theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    backgroundImage: `url(${Background})`,
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    width: `calc(100vw - 240px)`,
    minHeight: '100vh',
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing.unit * 6}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
    marginTop: -theme.spacing.unit * 3
  },
  toastsContainer: {
    width: 400,
    marginTop: theme.spacing.unit * 6,
    right: 0,
  }
});

export default withStyles(styles)(Layout);
