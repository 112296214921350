import React from 'react';
import {
  Drawer,
  IconButton,
  List } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  BorderAll as TableIcon,
  ArrowBack as ArrowBackIcon,
  Book as BookIcon,
  Videocam as VideocamIcon
} from "@material-ui/icons";
import classNames from 'classnames';
import PersonIcon from '@material-ui/icons/Person';
import SidebarLink from './components/SidebarLink/SidebarLinkContainer';

const structure = [
  { id: 0, permission: 'devices-list', label: 'Устройства',   link: '/devices',   icon: <HomeIcon /> },
  { id: 1, permission: 'cameras-list', label: 'Камеры',       link: '/cameras',   icon: <VideocamIcon /> },
  { id: 2, permission: 'users-list',   label: 'Пользователи', link: '/users',     icon: <PersonIcon /> },
  { id: 5, permission: 'devices-list', label: 'Справочники',  link: '/directory', icon: <BookIcon />},
  {/*
    id: 4,
    label: 'UI Elements',
    link: '/app/ui',
    icon: <UIElementsIcon />,
    children: [
      { label: 'Icons', link: '/app/ui/icons' },
      { label: 'Charts', link: '/app/ui/charts' },
      { label: 'Maps', link: '/app/ui/maps' },
        { id: 3, permission: 'notifications-list', label: 'Notifications', link: '/app/notifications', icon: <NotificationsIcon />},
        { id: 4, permission: 'devices-list', label: 'Icons', link: '/icons', icon: <NotificationsIcon />},
    ],
  */},
];

const SidebarView = ({ classes, theme, toggleSidebar, isSidebarOpened, isPermanent, location, ...props }) => {
  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton
          onClick={toggleSidebar}
        >
          <ArrowBackIcon classes={{ root: classNames(classes.headerIcon, classes.headerIconCollapse) }} />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => 
          props.Permissions.indexOf(link.permission) !== -1 ? 
            <SidebarLink key={link.id} location={location} isSidebarOpened={isSidebarOpened} {...link} />
            : null 
        )}
      </List>
    </Drawer>
  );
}

const drawerWidth = 240;

const styles = theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  sidebarList: {
    marginTop: theme.spacing.unit * 6,
  },
  mobileBackButton: {
    marginTop: theme.spacing.unit * .5,
    marginLeft: theme.spacing.unit * 3,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing.unit * .625,
    },
    [theme.breakpoints.up("md")]: {
      display: 'none',
    }
  }
});

export default withStyles(styles, { withTheme: true })(SidebarView);
