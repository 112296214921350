import React from "react";
import {
  withStyles,
  LinearProgress,
} from "@material-ui/core";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import DevicesList from "../../components/Devices/List"
import DevicesForm from "../../components/Devices/Form";
import DevicesFilterForm from "../../components/Common/DevicesFilterForm";

require('./icons/css/weather-icons.css');

class Devices extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        openAddDevicesForm: false
      };
    }

    handleOpenAddDeviceForm(e){
      this.setState({
        openAddDevicesForm: true
      }); 
    }
    handleCloseAddDeviceForm(){
      this.setState({
        openAddDevicesForm: false
      })
    }
    handleAddDevice(device){
      this.props.addDevice( device );
      this.setState({
        openAddDevicesForm: false
      })
    }

    render(){
      const { classes, isLoading, devices, units, permissions } = this.props;
      const groups = {};
      this.props.devicesGroups.map( (group) => { 
          Object.assign(groups, {[group.id]: group})
        }
      );
  return (
    <React.Fragment>
      {isLoading && 
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      }
      {/*(permissions.indexOf('devices-create') !== -1)?
        <PageTitle isLoading={isLoading} title="Устройства" button="Добавить" buttononClick={() => {this.handleOpenAddDeviceForm()}} />
        : <PageTitle isLoading={isLoading} title="Устройства" />*/
      }
      {devices.list && 
        <DevicesList
          units = {units}
          refreshDevice = { this.props.refreshDevice }
         />}

      <SpeedDial
        ariaLabel="actions"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={ () => {this.props.setOpenSpeedDial(false)} }
        onOpen={() => {this.props.setOpenSpeedDial(true)}}
        open={this.props.openSpeedDial}
        direction={'left'}
      >
        {(permissions.indexOf('devices-create') !== -1) &&
          <SpeedDialAction
            key={"AddDevice"}
            icon={<AddIcon />}
            tooltipTitle={"Добавить устройство"}
            onClick={() => {this.handleOpenAddDeviceForm()}}
          />
        }
          <SpeedDialAction
            key={"Filter"}
            icon={<SearchIcon />}
            tooltipTitle={"Фильтр"}
            onClick={() => { this.props.setOpenFilterForm(true)}}
          />
        </SpeedDial>

      <DevicesForm 
          title="Добавить"
          groups = {this.props.devicesGroups}
          open = { this.state.openAddDevicesForm }
          submitAction = { (device) => { this.handleAddDevice(device) } }
          cancelAction = { () => { this.handleCloseAddDeviceForm() } }
      />

    <DevicesFilterForm 
      groups = { this.props.devicesGroups }
      openForm = { this.props.openFilterForm }
      filter = { this.props.filter }
      submitAction = { (filter) => {
        localStorage.setItem("deviceFilter", JSON.stringify(filter));
        this.props.setFilter(filter); 
        this.props.setOpenFilterForm(false);
        this.props.getDevicesList(filter);       
      } }
      cancelAction = { () => {
        this.props.setOpenFilterForm(false)
      }}
    />
      
    </React.Fragment>
  );
    }
};

const styles = theme => ({
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing.unit * 3
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(5),
      right: theme.spacing(5),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(5),
      left: theme.spacing(5),
    },
  },
});


export default withStyles(styles, { withTheme: true })(Devices);
