import {
  GET_PARAMS_DATA_REQUEST,
  GET_PARAMS_DATA_SUCCESS,
  GET_PARAMS_DATA_FAILURE } from '../constants/DevicesParams';
import Axios from 'axios';

export const getParamsData = (deviceid, fromDate, toDate, limit) => dispatch => {
    dispatch({ type: GET_PARAMS_DATA_REQUEST });
    Axios.get('/api/devices/'+deviceid+'/params', 
      {params: {
        fromDate: fromDate,
        toDate: toDate,
        limit: limit }
      })
        .then(res => {
          let params = {};
          res.data.params.map(param => {
            Object.assign(params, { [param.id]: param })
          });
          dispatch({
            type: GET_PARAMS_DATA_SUCCESS,
            data: res.data.data,
            params: params,
            table: res.data.table
          });
        }).catch(function (error) {
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({ type: GET_PARAMS_DATA_FAILURE });
      });
  }