import { 
    GET_DEVICES_GROUP_LIST_REQUEST,
    GET_DEVICES_GROUP_LIST_SUCCESS,
    GET_DEVICES_GROUP_LIST_FAILURE,
    ADD_DEVICES_GROUP_REQUEST,
    ADD_DEVICES_GROUP_SUCCESS,
    ADD_DEVICES_GROUP_FAILURE,
    UPDATE_DEVICES_GROUP_REQUEST,
    UPDATE_DEVICES_GROUP_SUCCESS,
    UPDATE_DEVICES_GROUP_FAILURE,
    DELETE_DEVICES_GROUP_REQUEST,
    DELETE_DEVICES_GROUP_SUCCESS,
    DELETE_DEVICES_GROUP_FAILURE
  } from '../constants/DevicesGroups';
  
  export const initialState = {
    isLoading: false,
    list: [],
    page: 1,
    total_pages: 1,
    per_page: 15,
    error: null,
  };
  
  export default function UnitsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_DEVICES_GROUP_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_DEVICES_GROUP_LIST_SUCCESS:
        return {
          ...state,
          list: action.list,
          page: action.page,
          total_pages: action.total_pages,
          isLoading: false,
          error: null
        };
      case GET_DEVICES_GROUP_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true
        };
      case ADD_DEVICES_GROUP_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ADD_DEVICES_GROUP_SUCCESS:
        return {
          ...state,
          list: state.list.concat([action.group]),
          isLoading: false,
          error: null
        };
      case ADD_DEVICES_GROUP_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true
        };
      case UPDATE_DEVICES_GROUP_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case UPDATE_DEVICES_GROUP_SUCCESS:
        return {
          ...state,
          list: state.list.map((item) => {return item.id===action.group.id ? action.group : item} ),
          isLoading: false,
          error: null
        };
      case UPDATE_DEVICES_GROUP_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true
        };
      case DELETE_DEVICES_GROUP_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case DELETE_DEVICES_GROUP_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.id),
          isLoading: false
        };
      case DELETE_DEVICES_GROUP_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true
        };
      default:
        return state;
    }
  }
  