import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import themes, { overrides } from '../themes';
import Layout from '../containers/Layout';
import Login from '../containers/auth';
import Preloader from './Preloader/Circular'
window.Pusher = require('pusher-js');

const theme = createMuiTheme({...themes.default, ...overrides});

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest} render={props => (
        isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    )}
    />
  );
};

class App extends React.Component {
  constructor(props){
    super(props);
    this.props.loadSavedSession();
  }
  render() {
    const { isAuthenticated } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        { isAuthenticated === null ?
          <Preloader />
        :
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute isAuthenticated={ isAuthenticated } path="/" component={ Layout } />
            </Switch>
          </BrowserRouter>
        }
      </MuiThemeProvider>
    );
  }
}
  

export default App;