import { 
  GET_UNITS_LIST_REQUEST,
  GET_UNITS_LIST_SUCCESS, 
  GET_UNITS_LIST_FAILURE,
  ADD_UNITS_REQUEST,
  ADD_UNITS_SUCCESS,
  ADD_UNITS_FAILURE,
  UPDATE_UNITS_REQUEST,
  UPDATE_UNITS_SUCCESS,
  UPDATE_UNITS_FAILURE,
  DELETE_UNITS_REQUEST,
  DELETE_UNITS_SUCCESS,
  DELETE_UNITS_FAILURE,
} from '../constants/Units';

export const initialState = {
  isLoading: false,
  current: null,
  list: null,
  page: 1,
  total_pages: 1,
  per_page: 15,
  error: null,
};

export default function UnitsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNITS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_UNITS_LIST_SUCCESS:
      return {
        ...state,
        list: action.list,
        page: action.page,
        total_pages: action.total_pages,
        isLoading: false,
        error: null
      };
    case GET_UNITS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case ADD_UNITS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ADD_UNITS_SUCCESS:
      return {
        ...state,
        list: state.list.concat([action.unit]),
        isLoading: false,
        error: null
      };
    case ADD_UNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case UPDATE_UNITS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_UNITS_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {return item.id===action.unit.id ? action.unit : item} ),
        isLoading: false,
        error: null
      };
    case UPDATE_UNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case DELETE_UNITS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_UNITS_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.id),
        isLoading: false
      };
    case DELETE_UNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
}
