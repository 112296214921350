import axios from 'axios';
import {
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST
} from '../constants/Users';
import { toast } from 'react-toastify';

export const getUsersList = () => dispatch => {
  dispatch({type: GET_USERS_LIST_REQUEST});
  axios.get(`/api/users`)
      .then(res => {
        dispatch({
          type: GET_USERS_LIST_SUCCESS, 
          data: res.data
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_USERS_LIST_FAILURE});
    });
};

export const addUser = (user) => dispatch => {
  //dispatch(addDeviceRequest());
  axios.post('/api/users', user)
      .then(res => {
        toast.success("Пользователь добавлен!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({type: ADD_USER_SUCCESS, user: res.data});
      }).catch(function (error) {
        toast.error("Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        //dispatch(addDeviceFailure());
    });
};

export const editUser = (user) => dispatch => {
  //dispatch(addDeviceRequest());
  axios.put('/api/users/'+user.id, user)
      .then(res => {
        toast.success("Пользователь изменен!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({type: EDIT_USER_SUCCESS, user: res.data.user});
      }).catch(function (error) {
        toast.error("Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        //dispatch(addDeviceFailure());
    });
};

export const deleteUser = (id) => dispatch => {
  dispatch({ type: DELETE_USER_REQUEST });
  axios.delete('/api/users/'+id)
      .then(res => {
        toast.success("Пользователь удален!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: DELETE_USER_SUCCESS, id: id });
      }).catch(function (error) {
        toast.error("Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_USER_FAILURE });
    });
};