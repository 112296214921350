import React from "react";
import {
  Grid,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import UsersList from "../../components/Users/UsersList";
import UserForm from "../../components/Users/UserForm";
import ConformationDialog from '../../components/Common/ConformationDialog';

class Users extends React.Component {
  constructor(props) {
    super(props);
  }

  changeOpenAddForm(){
    this.props.setOpenForm(!this.props.openForm)
  }

  clickDeleteUser(id){
    this.props.setOpenConfirmationDialog(true);
    this.props.setSelectedUserId(id);
  }

  render(){
    const { classes, theme, isLoading, users, openForm, permissions, devices, editUser, permissionsAuthUser } = this.props;
    return (
      <React.Fragment>
        {isLoading && 
          <div className={classes.loader}>
            <LinearProgress />
          </div>
        }
        {(permissionsAuthUser.indexOf('users-create') !== -1)?
          <PageTitle isLoading={isLoading} title="Пользователи" button="Добавить" buttononClick={() => {this.changeOpenAddForm()}} />
          : <PageTitle isLoading={isLoading} title="Пользователи" />
        }
        {users && 
          <UsersList 
            permissionsAuthUser={permissionsAuthUser}
            users={users} 
            permissions={permissions}
            editUser={editUser}
            devices={devices}
            clickDeleteUser={(id) => {this.clickDeleteUser(id)}}
          />
        }

      
        {(permissionsAuthUser.indexOf('users-create') !== -1)?
          <UserForm
            openForm={openForm}
            nameForm={"Добавить пользователя"}
            permissions={permissions}
            devices={devices}
            submitAction={ (user) => {this.props.addUser(user)}} 
            cancelAction={() => {this.changeOpenAddForm()}}
          />
          :<div></div>
        }

        {
          //удаление
        }
        <ConformationDialog
          message="Вы уверены что хотите удалить?"
          openForm={this.props.openConfirmationDialog}
          submitLabel="Да"
          cancelLabel="Нет"
          submitAction={() => {
            this.props.deleteUser(this.props.selectedUserId);
            this.props.setOpenConfirmationDialog (false);
          }}
          cancelAction={() => {
            this.props.setOpenConfirmationDialog (false);
          }}
        />
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing(3)
  }
});

export default withStyles(styles, { withTheme: true })(Users);
