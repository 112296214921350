import React from "react";
import {
  Grid,
  withStyles
} from "@material-ui/core";
import DevicesListItem from "./ListItem";
import { connect } from "react-redux";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { devices, units, refreshDevice } = this.props;
    return (
      <Grid container >
        {Object.keys(devices).map(id =>
          <DevicesListItem
            device={devices[id]}
            units = { units }
            refresh = { refreshDevice }
          />
        )}    
      </Grid>
    )
  };
}

const styles = theme => ({
    ParamItemContainer: {
      display: 'flex',
      minWidth: '100%',
      minHeight: 100,
      overflowX: 'auto',
      flexWrap: 'inherit',
    },
    ParamItem: {
      minWidth: 100,
      margin: '5px',
      padding: '5px',
    },
    root: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },
    card: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column"
    },
    visitsNumberContainer: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      paddingBottom: theme.spacing.unit
    },
    paper: {
      padding: theme.spacing.unit * 2,
      color: theme.palette.text.secondary,
    },
    progressSection: {
      marginBottom: theme.spacing.unit
    },
    progressTitle: {
      marginBottom: theme.spacing.unit * 2
    },
    pieChartLegendWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      marginRight: theme.spacing.unit
    },
    legendItemContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing.unit
    },
    fullHeightBody: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "space-between"
    },
    tableWidget: {
      overflowX: "auto"
    },
    progressBar: {
      backgroundColor: theme.palette.warning.main
    },
    performanceLegendWrapper: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      marginBottom: theme.spacing.unit
    },
    legendElement: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing.unit * 2,
    },
    legendElementText: {
      marginLeft: theme.spacing.unit
    },
    serverOverviewElement: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%"
    },
    serverOverviewElementText: {
      minWidth: 145,
      paddingRight: theme.spacing.unit * 2
    },
    serverOverviewElementChartWrapper: {
      width: "100%"
    },
    mainChartBody: {
      overflowX: 'auto',
    },
    mainChartHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.only("xs")]: {
        flexWrap: 'wrap',
      }
    },
    mainChartHeaderLabels: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.only("xs")]: {
        order: 3,
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
      }
    },
    mainChartHeaderLabel: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing.unit * 3,
    },
    mainChartSelectRoot: {
      borderColor: theme.palette.text.hint + '80 !important',
    },
    mainChartSelect: {
      padding: 10,
      paddingRight: 25
    },
    mainChartLegentElement: {
      fontSize: '18px !important',
      marginLeft: theme.spacing.unit,
    }
  });
  
const mapStateToProps = state => {
  return {
    devices: state.devices.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //increment: () => dispatch({ type: "INCREMENT" }),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles, { withTheme: true })(List));