import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

class ConformationDialog extends React.Component {
    render(){
        const { message, openForm, submitLabel, cancelLabel, submitAction, cancelAction } = this.props;
        return (
            <Dialog open={ openForm } onClose={()=>{ cancelAction()}}>
                <DialogContent>
                    { message }
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{ cancelAction() }} color="primary">
                    { cancelLabel }
                </Button>
                <Button onClick={()=>{ submitAction() }} color="primary">
                    { submitLabel }
                </Button>
                </DialogActions>
            </Dialog>
        )};
    }
    const styles = theme => ({});
    export default withStyles(styles, { withTheme: true })(ConformationDialog);