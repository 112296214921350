import React from "react";
import {
  Grid,
  withStyles
} from "@material-ui/core";
import CameraListItem from "./listItem";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { list } = this.props;
    return (
      <Grid container >
        {list.map( item =>
          <CameraListItem
            item = { item }
          />
        )
        }    
      </Grid>
    )
  };
}

const styles = theme => ({
    
  });

export default withStyles(styles, { withTheme: true })(List);