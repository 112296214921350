import React from "react";
import {
  withStyles,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
  Grid,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Slide,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { Button } from "@material-ui/core/es";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
var _ = require('lodash');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class NotificationsForm extends React.Component {
    
  constructor(props) {
      super(props);
      this.state = {
          notifications: {
              device_status: false,
              params: null
          },
          newNotification:{
            id_param: 0,
            condition: '<=',
            value: ""
          },
          addNotificationForm: false
      };
    }

    componentDidUpdate(prevProps) {
      if ((!_.isEqual(this.props.notifications.params, prevProps.notifications.params))||(this.state.notifications.params === null)) {
        this.setState({
          notifications: {
            ...this.props.notifications
          }
        })
      }
    }

    render(){
        const { classes, title, submitAction, cancelAction, open } = this.props;
        const { notifications, addNotificationForm, newNotification } = this.state;
        let params = {}
        this.props.params.map((param) => {
          params[param.id] = param
        })
        return (
            <React.Fragment>

            <Dialog fullScreen open={ open } TransitionComponent={Transition} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                    <Typography variant="h6" className={classes.title} style={{color: "#ffffff"}}>
                        { title }
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={ () => cancelAction() } aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    </Toolbar>
                </AppBar>

                <Paper className={classes.paper}> 
                    <Typography variant="h4">Устройство</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={ notifications.device_status }
                        onChange={(e) => {
                          this.setState(prevState => ({
                            notifications: {
                              ...prevState.notifications,
                              device_status: !prevState.notifications.device_status
                            }
                          }))
                        }}
                        color="primary"
                        />
                    }
                    label="Уведомлять при изменении статуса"
                    />
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="h4">
                          Параметры
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Fab size="small" color="primary" aria-label="add" onClick={ () => {this.setState({addNotificationForm: true})} }>
                          <AddIcon />
                        </Fab>
                      </Grid>
                    </Grid>
                    <List dense={true}>
                    { notifications.params &&
                      Object.keys( notifications.params ).map(paramid => 
                        <ListItem>
                          <ListItemAvatar>
                              <Avatar>
                                <i className={ params[notifications.params[paramid].id_param].classIcon}></i>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={ params[notifications.params[paramid].id_param].label}
                            secondary={ params[notifications.params[paramid].id_param].name}
                          />
                          <ListItemText style={{textAlign: 'right'}}
                            primary={this.state.notifications.params[paramid].condition}
                          />
                          <ListItemText style={{textAlign: 'right'}}
                            primary={this.state.notifications.params[paramid].value}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" 
                            onClick={(e)=>{
                                var flparams = {};
                                Object.keys(this.state.notifications.params).map( (id) => { 
                                    if(Number(id)!==Number(paramid)) Object.assign(flparams, {[id]: notifications.params[id]})
                                });
                                this.setState(prevState => ({
                                  notifications: {
                                    ...prevState.notifications,
                                    params: flparams
                                  }
                                }))
                            }}
                            >
                            <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    )}
                    </List>
                    <Button onClick={()=>{
                        submitAction( notifications );
                    }} color="primary">
                      Сохранить
                    </Button>
                </Paper>
              </Dialog>
              {
                /*
                // Форма Добавления уведомления
                */
              }
              
              <Dialog open={ addNotificationForm } onClose={ () => { this.setState({addNotificationForm: false})} }>
                <DialogContent>
                    <FormControl>
                      <InputLabel htmlFor="id_param">Параметр</InputLabel>
                      <Select
                        value={newNotification.id_param}
                        onChange={(e) => { 
                          e.persist();
                          this.setState(prevState => ({
                            ...prevState,
                            newNotification: {
                              ...prevState.newNotification,
                              id_param: e.target.value
                            }
                          }))
                        }}
                        inputProps={{
                          name: 'id_param',
                          id: 'id_param',
                        }}
                      >
                        
                      {params.length !== 0 &&
                        Object.keys(params).map(id => 
                          <MenuItem value={params[id].id}>{params[id].label}</MenuItem>
                        )
                      }
                      </Select>
                    </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="condition">Условие</InputLabel>
                    <Select
                      value={newNotification.condition}
                      onChange={(e) => {
                        this.setState(prevState => ({
                          newNotification: {
                            ...prevState.newNotification,
                            condition: e.target.value
                          }
                        }))
                      }}
                      inputProps={{
                        name: 'condition',
                        id: 'condition',
                      }}
                    >
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <TextField
                      autoFocus
                      id="label"
                      label="Значение"
                      onChange={(e)=>{
                        let val = e.target.value;
                        this.setState(prevState => ({
                          newNotification: {
                            ...prevState.newNotification,
                            value: val
                          }
                        }))}}
                      value={newNotification.value}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    this.setState({addNotificationForm: false})}
                  } color="primary">
                    Отмена
                  </Button>
                  <Button onClick={()=>{
                    this.setState(prevState => ({
                      notifications: {
                        ...prevState.notifications,
                        params: Object.assign( {}, Object.assign(prevState.notifications.params, {[(new Date()).getTime()]: JSON.parse( JSON.stringify(this.state.newNotification))}))
                      }
                    }))
                    this.setState({addNotificationForm: false})
                  }} color="primary">
                    Добавить
                  </Button>
                </DialogActions>
              </Dialog>
              
            </React.Fragment>
        );
    }
};

const styles = theme => ({
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'fixed',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    appBar: {
        position: 'relative',
    },
    title: {
      marginLeft: theme.spacing.unit * 2,
      flex: 1,
    },
    progress: {
      margin: theme.spacing.unit * 2,
    },
    paper: {
      marginTop: theme.spacing.unit * 2,
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 2,
      color: theme.palette.text.secondary,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing.unit * 2,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
})

export default withStyles(styles, { withTheme: true })(NotificationsForm);
