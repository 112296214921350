import React from "react";
import { Dialog, DialogContent, DialogActions, Button, Select, Input, Chip, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

class DevicesFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          filter: (localStorage.getItem('deviceFilter')) ? JSON.parse(localStorage.getItem('deviceFilter')) : { groups: [0] }
        };
    }

    componentDidUpdate(prevProps) {
        if ((this.props.filter !== prevProps.filter) && (this.props.filter !== null)) {
          this.setState({
            filter: this.props.filter
          })
        }
      }
    
    handleChangeGroups = event => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: event.target.value
            }
        });
    };

    render(){
        const { classes, groups, openForm, submitAction, cancelAction } = this.props;
        return (
            <Dialog open={ openForm } onClose={()=>{ cancelAction()}}>
                <DialogContent>
                    <Select
                    labelId="groups-label"
                    id="groups"
                    multiple
                    value={this.state.filter.groups}
                    onChange={this.handleChangeGroups}
                    input={<Input fullWidth />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                        {selected.map(value => (
                            <Chip
                            key={Number(value)} 
                            label={groups.filter( group => group.id === value)[0].name} 
                            className={classes.chip} 
                            onDelete={() => {
                                this.setState({
                                    filter: {
                                        ...this.state.filter,
                                        groups: this.state.filter.groups.filter( id => id !== Number(value))
                                    }
                                })}
                            }/>
                        ))}
                        </div>
                    )}
                    >
                    
                    { groups.map((group) => (
                        <MenuItem dense={true} key={ Number(group.id) } value={ Number(group.id) } >
                            {group.name}
                        </MenuItem>
                        ))
                    } 
                    </Select>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{ cancelAction() }} color="primary">
                    Отмена
                </Button>
                <Button onClick={()=>{ submitAction(this.state.filter) }} color="primary">
                    Применить
                </Button>
                </DialogActions>
            </Dialog>
        )};
    }
    const styles = theme => ({
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
          },
          chip: {
            margin: 2,
          },
    });
    export default withStyles(styles, { withTheme: true })(DevicesFilterForm);