import {
    GET_CAMERA_REQUEST,
    GET_CAMERA_SUCCESS,
    GET_CAMERA_FAILURE,
    GET_CAMERA_LIST_REQUEST,
    GET_CAMERA_LIST_SUCCESS,
    GET_CAMERA_LIST_FAILURE,
    CREATE_CAMERA_REQUEST,
    CREATE_CAMERA_SUCCESS,
    CREATE_CAMERA_FAILURE,
    UPDATE_CAMERA_REQUEST,
    UPDATE_CAMERA_SUCCESS,
    UPDATE_CAMERA_FAILURE,
    DELETE_CAMERA_REQUEST,
    DELETE_CAMERA_SUCCESS,
    DELETE_CAMERA_FAILURE 
  } from '../constants/Cameras';
  
  window.io = require('socket.io-client');
  
  export const initialState = {
    page: 1,
    list: [],
    current: null,
    total: 0,
    isLoading: false,
    isLoadingList: false,
    error: null
  };
  
  export default function DevicesReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CAMERA_LIST_REQUEST:
        return {
          ...state,
          isLoadingList: true,
        };
      case GET_CAMERA_LIST_SUCCESS:
        return {
          ...state,
          list: action.list,
          total: action.total,
          page: action.page,
          isLoadingList: false,
          error: null,
        };
      case GET_CAMERA_LIST_FAILURE:
        return {
          ...state,
          isLoadingList: false,
          error: true,
        };
      case GET_CAMERA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case GET_CAMERA_SUCCESS:
        return {
          ...state,
          current: action.device,
          isLoading: false,
          error: null,
        };
      case GET_CAMERA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true,
        };
      case DELETE_CAMERA_SUCCESS:
        var dev = {};
        Object.keys(state.list).map(id => {
          if (Number(id) !== Number(action.id))
            Object.assign(dev, {[id]: state.list[id]});
        });
        return {
          ...state,
          isLoading: false,
          list: dev,
        };
      case DELETE_CAMERA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case CREATE_CAMERA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case CREATE_CAMERA_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  }
  