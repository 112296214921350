import React from "react";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

function TabPanel (props) {
    const {children, value, index, ...other} = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

const styles = theme => ({});
export default withStyles(styles, { withTheme: true })(TabPanel);