export const GET_DEVICES_LIST_REQUEST = "Devices/GET_DEVICES_LIST_REQUEST";
export const GET_DEVICES_LIST_SUCCESS = "Devices/GET_DEVICES_LIST_SUCCESS";
export const GET_DEVICES_LIST_FAILURE = "Devices/GET_DEVICES_LIST_FAILURE";

export const GET_DEVICE_REQUEST = "Devices/GET_DEVICE_REQUEST";
export const GET_DEVICE_SUCCESS = "Devices/GET_DEVICE_SUCCESS";
export const GET_DEVICE_FAILURE = "Devices/GET_DEVICE_FAILURE";

export const ADD_DEVICE_REQUEST = "Devices/ADD_DEVICE_REQUEST";
export const ADD_DEVICE_SUCCESS = "Devices/ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_FAILURE = "Devices/ADD_DEVICE_FAILURE";

export const UPDATE_DEVICE_REQUEST = "Devices/UPDATE_DEVICE_REQUEST";
export const UPDATE_DEVICE_SUCCESS = "Devices/UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_FAILURE = "Devices/UPDATE_DEVICE_FAILURE";

export const DEVICE_REFRESH = "Devices/DEVICE_REFRESH";

export const DELETE_DEVICE_REQUEST = "Devices/DELETE_DEVICE_REQUEST";
export const DELETE_DEVICE_SUCCESS = "Devices/DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAILURE = "Devices/DELETE_DEVICE_FAILURE";

export const UPLOAD_SCATCH_REQUEST = "Devices/UPLOAD_SCATCH_REQUEST";
export const UPLOAD_SCATCH_SUCCESS = "Devices/UPLOAD_SCATCH_SUCCESS";
export const UPLOAD_SCATCH_FAILURE = "Devices/UPLOAD_SCATCH_FAILURE";

export const SEND_COMMAND_REQUEST = "Devices/SEND_COMMAND_REQUEST";
export const SEND_COMMAND_SUCCESS = "Devices/SEND_COMMAND_SUCCESS";
export const SEND_COMMAND_FAILURE = "Devices/SEND_COMMAND_FAILURE";

export const ADD_NOTIFICATIONS_REQUEST = "Notifications/ADD_NOTIFICATIONS_REQUEST";
export const ADD_NOTIFICATIONS_SUCCESS = "Notifications/ADD_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_FAILURE = "Notifications/ADD_NOTIFICATIONS_FAILURE";

export const EDIT_NOTIFICATIONS_REQUEST = "Notifications/EDIT_NOTIFICATIONS_REQUEST";
export const EDIT_NOTIFICATIONS_SUCCESS = "Notifications/EDIT_NOTIFICATIONS_SUCCESS";
export const EDIT_NOTIFICATIONS_FAILURE = "Notifications/EDIT_NOTIFICATIONS_FAILURE";

export const UPDATE_DEVICE_PARAM_REQUEST = "DevicesParams/UPDATE_DEVICE_PARAM_REQUEST";
export const UPDATE_DEVICE_PARAM_SUCCESS = "DevicesParams/UPDATE_DEVICE_PARAM_SUCCESS";
export const UPDATE_DEVICE_PARAM_FAILURE = "DevicesParams/UPDATE_DEVICE_PARAM_FAILURE";