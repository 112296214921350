import React from 'react';
import {
  Grid,
  withStyles,
  Paper,
  LinearProgress,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Button,
  Tooltip,
} from '@material-ui/core';
import {Typography} from '../../components/Wrappers';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import Chart from '../../components/DevicesParams/Chart';

import ParamForm from '../../components/DevicesParams/Form';
import ConformationDialog from '../../components/Common/ConformationDialog';
import TabPanel from '../../components/Common/TabPanel';
import DevicesForm from '../../components/Devices/Form';
import ComandsForm from '../../components/Devices/ComandsForm';
import DevicesNotificationsForm
  from '../../components/Devices/NotificationsForm';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {NotificationsNone as NotificationsIcon} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BuildIcon from '@material-ui/icons/Build';

import MUIDataTable from 'mui-datatables';
import Echo from 'laravel-echo';
import Axios from 'axios';

import { Player } from 'video-react';
import HLSSource from '../../components/HLS/HLSSource';
import "video-react/dist/video-react.css";

require ('./icons/css/weather-icons.css');

class Devices extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      openAddDevicesForm: false,
      addDevice: null,
    };
  }
  
  componentDidMount(){
    new Echo({
      broadcaster: 'pusher',
      key: `${process.env.REACT_APP_WS_KEY}`,
      wsHost: `${process.env.REACT_APP_WS_HOST}`,
      wsPort: `${process.env.REACT_APP_WS_PORT}`,
      disableStats: true,
      authEndpoint: `${process.env.REACT_APP_WS_AUTH_ENDPOINT_URL}`,
      auth: {
        headers: {
          Authorization: Axios.defaults.headers.common['Authorization']
        }
      },
    }).private(`device.`+parseInt(this.props.match.params.id, 10))
          .listen('.DeviceEvent', (e) => {
            //refreshDevice(JSON.parse(e[0])[0]);
            console.log(e)
          });
  }

  clickOpenAddDeviceForm (e) {
    this.setState ({
      openAddDevicesForm: true,
      addDevice: {
        name: '',
        key: '',
        update: '',
      },
    });
  }
  handleCloseAddDeviceForm () {
    this.setState ({
      openAddDevicesForm: false,
    });
  }
  clickAddDevice () {
    this.props.addDevice (this.state.addDevice);
    this.setState ({
      openAddDevicesForm: false,
    });
  }

  render () {
    const {
      classes,
      isLoadingDevice,
      device,
      openEditParamForm,
      setOpenCommandsForm,
      permissions,
      units
    } = this.props;
    
    const groups = {};
    this.props.devicesGroups.map( (group) => { 
        Object.assign(groups, {[group.id]: group})
      }
    );

    return (
      <React.Fragment>
        {isLoadingDevice &&
          <div className={classes.loader}>
            <LinearProgress />
          </div>}
        {device &&
          <div>
            
              <Grid container direction="row">
                { device.video &&
                  <Grid className={classes.paper} item lg={4} md={6} sm={12} xs={12}>
                    <Player>
                      <HLSSource
                        isVideoChild
                        src={device.video}
                      />
                    </Player>
                  </Grid>
                }
                <Grid item 
                  lg={device.video ? 8 : 12} 
                  md={device.video ? 6 : 12} 
                  sm={device.video ? 12 : 12} 
                  xs={12}>
                <Paper className={classes.paper} style={{width: '100%', height: '97%'}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h4">{device.name}</Typography>
                    </Grid>
                    <Grid item xs style={{textAlign: 'right'}}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          this.props.setOpenNotificationsForm (true);
                        }}
                      >
                        <NotificationsIcon />
                      </IconButton>
                      {permissions.indexOf ('devices-edit') !== -1 &&
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            this.props.setOpenCommandsForm (true);
                          }}
                        >
                          <BuildIcon />
                        </IconButton>}
                      {permissions.indexOf ('devices-edit') !== -1 &&
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            this.props.setOpenDeviceEditForm (true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>}
                      {permissions.indexOf ('devices-delete') !== -1 &&
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            this.props.setOpenConfirmationDialog (true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>}
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        color={device.status === 'on' ? 'primary' : 'error'}
                      >
                        {device.status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    Данные получены:
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {device.lastUpdate}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    key:
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {device.key}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">
                    update:
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {device.update / 1000} сек.
                  </Typography>
                </Grid>
              </Grid>
                  <Divider className={classes.divider} />
                  {device.params.length === 0 &&
                    <Grid
                  className={classes.ParamItemContainer}
                  container
                  xs={12}
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    className={classes.ParamItem}
                    justify="center"
                    alignItems="center"
                    item
                  >
                    <Typography variant="caption" display="block" gutterBottom>
                      Нет данных
                    </Typography>
                  </Grid>
                </Grid>}
                  {device.params.length !== 0 &&
                    <Grid
                  className={classes.ParamItemContainer}
                  container
                  xs={12}
                  spacing={2}
                  //wrap="nowrap"
                >
                  {device.params.map (param => (
                    <Grid item 
                      direction="row"
                      style={{width: '12em'}}>
                      <Tooltip title={param.label}>
                        <div className={classes.cardContent} 
                          onClick={() => {
                            this.props.setSelectedParam (param);
                            this.props.setOpenEditParamForm (true);
                          }}>
                          <Grid item direction="row">
                            <Typography variant="h3" display="inline" >
                                <i className={param.classIcon}></i>
                            </Typography>
                            <Typography variant="h6" display="inline">
                              { " "+param.value+" " }
                            </Typography>
                            <Typography variant="body2" display="inline" gutterBottom> { (!!units)?units.filter(unit => unit.id === param.id_unit)[0].short:"" } </Typography>
                          </Grid>
                          <Grid item direction="column" wrap="nowrap" justify="center" alignItems="center">
                            <Typography variant="caption" display="block" noWrap>
                              {param.label} 
                            </Typography>
                          </Grid>                    
                        </div>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>}
                  </Paper>
                </Grid>
              </Grid>
            

            <Paper className={classes.paper}>
              <AppBar position="static">
                <Tabs
                  value={this.props.tab}
                  onChange={(event, newValue) => {
                    this.props.setTab (newValue);
                  }}
                  indicatorColor="secondary"
                  variant="fullWidth"
                >
                  <Tab value="chart" label="График" />
                  <Tab value="table" label="Таблица" />
                </Tabs>
              </AppBar>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Фильтр</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ruLocale}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-fromDate"
                      label="Дата от:"
                      value={this.props.fromDate}
                      onChange={val => {
                        this.props.setFromDate (val);
                      }}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-toDate"
                      label="Дата до:"
                      value={this.props.toDate}
                      onChange={val => {
                        this.props.setToDate (val);
                      }}
                    />
                    <FormControl className={classes.formControl}>
                      <InputLabel id="limit-label">
                        Колличество записей
                      </InputLabel>
                      <Select
                        labelId="limit-label"
                        id="limit-select"
                        value={this.props.limit}
                        onChange={event => {
                          this.props.setLimit (event.target.value);
                        }}
                      >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={1500}>1500</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.props.handleGetParamsData}
                    >
                      Поиск
                    </Button>
                  </MuiPickersUtilsProvider>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <TabPanel value={this.props.tab} index="chart">
                {this.props.devicesParamsData.isLoading
                  ? <div className={classes.loaderContainer}>
                      <CircularProgress size={80} className={classes.loader} />
                    </div>
                  : <Chart
                      params={this.props.devicesParamsData.params}
                      data={Object.keys (
                        this.props.devicesParamsData.list
                      ).map (id => {
                        return {
                          name: this.props.devicesParamsData.params[id].label,
                          data: this.props.devicesParamsData.list[
                            id
                          ].map (item => {
                            return {x: item.time, y: item.value};
                          }),
                        };
                      })}
                    />}
              </TabPanel>
              <TabPanel value={this.props.tab} index="table">
                {this.props.devicesParamsData.isLoading
                  ? <div className={classes.loaderContainer}>
                      <CircularProgress size={80} className={classes.loader} />
                    </div>
                  : <div>
                      <MUIDataTable
                        data={this.props.devicesParamsData.table}
                        columns={['Время'].concat (
                          Object.keys (
                            this.props.devicesParamsData.params
                          ).map (
                            id => this.props.devicesParamsData.params[id].label
                          )
                        )}
                        options={{
                          filterType: 'dropdown',
                          responsive: 'scroll',
                        }}
                      />
                    </div>}
              </TabPanel>
            </Paper>
          </div>}

        {
          //Редактирование параметра
        }
        <ParamForm
          openForm={openEditParamForm}
          param={this.props.selectedParam}
          title={'Изменить'}
          submitAction={param => {
            this.props.updateDeviceParam (param);
            this.props.setOpenEditParamForm (false);
            this.props.getDevice (parseInt (this.props.match.params.id, 10));
          }}
          cancelAction={() => {
            this.props.setOpenEditParamForm (false);
          }}
          units={this.props.units}
        />

        {
          //удаление
        }
        <ConformationDialog
          message="Вы уверены что хотите удалить?"
          openForm={this.props.openConfirmationDialog}
          submitLabel="Да"
          cancelLabel="Нет"
          submitAction={() => {
            this.props.deleteDevice (device.id);
            this.props.setOpenConfirmationDialog (false);
            this.props.history.push ('/');
          }}
          cancelAction={() => {
            this.props.setOpenConfirmationDialog (false);
          }}
        />

        {
          //Редактирование устройства
        }
        <DevicesForm
          open={this.props.openDeviceEditForm}
          groups = { groups }
          device={device}
          title="Изменить"
          submitAction={device => {
            this.props.setOpenDeviceEditForm (false);
            this.props.updateDevice (device);
            this.props.getDevice (parseInt (this.props.match.params.id, 10));
          }}
          cancelAction={() => {
            this.props.setOpenDeviceEditForm (false);
          }}
        />

        {
          //Редактирование уведомлений устройства
        }
        {device &&
          <DevicesNotificationsForm
            title="Редактирование уведомлений устройства"
            submitAction={notification => {
              this.props.editNotifications (device.id, notification);
              this.props.setOpenNotificationsForm (false);
              this.props.getDevice (parseInt (this.props.match.params.id, 10));
            }}
            cancelAction={() => {
              this.props.setOpenNotificationsForm (false);
            }}
            open={this.props.openNotificationsForm}
            params={device.params}
            notifications={device.notifications}
          />}

        {
          //Форма комманд устройству
        }
        {device &&
          <ComandsForm
            title="Редактирование уведомлений устройства"
            closeAction={() => {
              setOpenCommandsForm (false);
            }}
            scatchUpload={this.props.scatchUpload}
            sendCommand={this.props.sendCommand}
            open={this.props.openCommandsForm}
            idDevice={device.id}
          />}

      </React.Fragment>
    );
  }
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  ParamItemContainer: {
    display: 'flex',
    minWidth: '100%',
    minHeight: 100,
    overflowX: 'auto',
    /*flexWrap: 'inherit',*/
  },
  ParamItem: {
    minWidth: '10em',
    margin: '5px',
    padding: '5px',
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginLeft: theme.spacing.unit * 4,
  },
});

export default withStyles (styles, {withTheme: true}) (Devices);
