export const GET_UNITS_LIST_REQUEST = "Units/GET_UNITS_LIST_REQUEST";
export const GET_UNITS_LIST_SUCCESS = "Units/GET_UNITS_LIST_SUCCESS";
export const GET_UNITS_LIST_FAILURE = "Units/GET_UNITS_LIST_FAILURE";

export const ADD_UNITS_REQUEST = "Units/ADD_UNITS_REQUEST";
export const ADD_UNITS_SUCCESS = "Units/ADD_UNITS_SUCCESS";
export const ADD_UNITS_FAILURE = "Units/ADD_UNITS_FAILURE";

export const UPDATE_UNITS_REQUEST = "Units/UPDATE_UNITS_REQUEST";
export const UPDATE_UNITS_SUCCESS = "Units/UPDATE_UNITS_SUCCESS";
export const UPDATE_UNITS_FAILURE = "Units/UPDATE_UNITS_FAILURE";

export const DELETE_UNITS_REQUEST = "Units/DELETE_UNITS_REQUEST";
export const DELETE_UNITS_SUCCESS = "Units/DELETE_UNITS_SUCCESS";
export const DELETE_UNITS_FAILURE = "Units/DELETE_UNITS_FAILURE";