import React from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  Input,
  Chip,
  DialogContent, 
  DialogActions, 
  Button
} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: {
        id: null,
        name: '',
        email: '',
        password: '',
        permissions: [],
        devices:[]
      }
    };
  }

  componentDidUpdate(prevProps) {
    if ((this.props.user !== prevProps.user) && (this.props.user !== null)) {
      this.setState({
        user: {
          ...this.props.user, 
          permissions: this.props.user.permissions.map((permission) => {
            return permission.id;
          }),
          devices: this.props.user.devices.map((device) => {
            return device.id;
          })
        }
    
      })
    }
  }

  handleChangePermission = event => {
    this.setState({
      user: {
        ...this.state.user,
        permissions: event.target.value
      }
    });
  };
  handleChangeDevice = event => {
    this.setState({
      user: {
        ...this.state.user,
        devices: event.target.value
      }
    });
  };

  render(){
    const { classes, theme, openForm, nameForm, submitAction, cancelAction, permissions, devices } = this.props;
    return (
      <React.Fragment>
     
      {openForm &&
        <Dialog open={openForm} onClose={() => cancelAction()}>
          <DialogTitle id="form-dialog-title">{nameForm}</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="label"
                label="Имя"
                onChange={(e)=>{this.state.user.name = e.target.value; this.forceUpdate()}}
                value={this.state.user.name}
                fullWidth
              />
              <TextField
                margin="dense"
                id="Email"
                label="Email"
                onChange={(e)=>{this.state.user.email = e.target.value; this.forceUpdate()}}
                value={this.state.user.email}
                fullWidth
              />
              <TextField
                margin="dense"
                id="label"
                label="Пароль"
                onChange={(e)=>{this.state.user.password = e.target.value; this.forceUpdate()}}
                value={this.state.user.password}
                fullWidth
              />
              <FormControl fullWidth={true} >
                <InputLabel id="permissions-label">Права</InputLabel>
                <Select
                  labelId="permissions-label"
                  id="permissions"
                  multiple
                  value={this.state.user.permissions}
                  onChange={this.handleChangePermission}
                  input={<Input fullWidth />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip 
                          key={value} 
                          label={permissions[value].display_name} 
                          className={classes.chip} 
                          onDelete={() => {
                            this.setState({
                              user: {
                                ...this.state.user,
                                permissions: this.state.user.permissions.filter( id => id !== value)
                              }
                            })}
                          }/>
                      ))}
                    </div>
                  )}
                >
                  
                  {Object.keys(permissions).map((key) => (
                      <MenuItem dense={true} key={key} value={ key } >
                        {permissions[key].display_name}
                      </MenuItem>
                    ))
                  } 
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="devices-label">Устройства</InputLabel>
                <Select
                  labelId="devices-label"
                  id="devices"
                  multiple
                  value={this.state.user.devices}
                  onChange={this.handleChangeDevice}
                  input={<Input id="select-multiple-device" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip 
                          key={value} 
                          label={devices[value]} 
                          className={classes.chip} 
                          onDelete={() => {
                            this.setState({
                              user: {
                                ...this.state.user,
                                devices: this.state.user.devices.filter( id => id !== value)
                              }
                            })}
                          }/>
                      ))}
                    </div>
                  )}
                  MenuProps={ {
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  } }
                >
                  {Object.keys(devices).map((key) => (
                      <MenuItem key={key} value={ key } >
                        {devices[key]}
                      </MenuItem>
                    ))
                  } 
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{cancelAction()}} color="secondary">
                Отмена
              </Button>
              <Button onClick={()=>{submitAction(this.state.user);}} color="primary">
                Сохранить
              </Button>
            </DialogActions>
      </Dialog>
      }
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});

export default withStyles(styles, { withTheme: true })(UserForm);
