import axios from 'axios';

import {
  GET_PERMISSIONS_LIST_REQUEST,
  GET_PERMISSIONS_LIST_SUCCESS,
  GET_PERMISSIONS_LIST_FAILURE } from '../constants/Permissions'

export const initialState = {
  isLoading: false,
  error: null
};

export default function PermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERMISSIONS_LIST_SUCCESS:
      return {
        ...state,
        permissions: action.permissions,
        devices: action.devices,
        isLoading: false
      };
    case GET_PERMISSIONS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_PERMISSIONS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
}
