import React from "react";
import {
  Grid,
  withStyles,
  Paper,
  Typography,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  Dialog,
  DialogTitle,
  TextField,
  Divider,
  Chip,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import PageTitle from "../PageTitle/PageTitle";
import { DialogContent, DialogActions, Button } from "@material-ui/core/es";
import UserForm from "./UserForm";
import DeleteIcon from '@material-ui/icons/Delete';

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      openForm: false
    };
  }

  openEditForm(user){
    this.setState({
        ...this.state,
        user: user,
        openForm: !this.state.openForm
    });
  }

  closeEditForm(){
    this.setState({
      ...this.state,
      openForm: !this.state.openForm
    });
  }

  render(){
    const { classes, users, permissions, devices, permissionsAuthUser } = this.props;
    const { openForm, user } = this.state;

    return (
      <React.Fragment>
        {users && 
          <Grid container spacing={3}>
            {users.map(user =>
              <Grid item lg={12} md={12} sm={12} xs={12} key={user.id}>
                <Paper>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        onClick={() => { 
                          if(permissionsAuthUser.indexOf('users-edit') !== -1)
                            this.openEditForm(user)}
                        }
                        primary={user.name}
                        secondary={user.email}
                      />
                      <ListItemSecondaryAction>
                            {permissionsAuthUser.indexOf('users-delete') !== -1 &&
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  this.props.clickDeleteUser(user.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>}
                      </ListItemSecondaryAction>
                    </ListItem>
                    { user.permissions.length>0 &&
                      <div>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                          <ListItemText
                            secondary="права"
                          />
                          <ListItemText 
                            primary={
                            <div className={classes.chips}>
                              {user.permissions.map(permission => 
                                <Chip size="small" key={permission.id} label={permission.display_name} className={classes.chip} />
                              )}
                            </div>}
                          />
                        </ListItem>
                      </div>
                    }
                    { user.devices.length>0 &&
                      <div>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                          <ListItemText
                            secondary="устройства"
                          />
                          <ListItemText 
                            primary={
                            <div className={classes.chips}>
                              {user.devices.map(device => 
                                <Chip size="small" key={device.id} label={device.name} className={classes.chip} />
                              )}
                            </div>}
                          />
                        </ListItem>
                      </div>
                    }
                  </List>
                </Paper>
              </Grid>
            )}
          </Grid>
        }
        {(permissionsAuthUser.indexOf('users-edit') !== -1) &&
          <UserForm
            openForm={openForm}
            nameForm={"Изменить пользователя"}
            permissions={permissions}
            devices={devices}
            user={user}
            submitAction={(user) => {
              this.props.editUser(user);
              this.setState({
                ...this.state,
                openForm: !this.state.openForm
              })}
            } 
            cancelAction={() => {this.closeEditForm()}}
          />
        }
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default withStyles(styles, { withTheme: true })(UsersList);
