import React from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField, DialogActions, Button, FormControl, InputLabel, Select, Input, Chip, MenuItem, FormControlLabel, Switch
} from "@material-ui/core";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          camera: {
            id: null,
            name: '',
            description: '',
            stream: '',
            substream: '',
            public: false
          }
        };
      }
    
    componentDidUpdate(prevProps) {
      if ((this.props.camera !== prevProps.camera) && (this.props.camera !== null)) {
        this.setState({
            camera: {
                ...this.props.camera
            }
        })
      }
    }

    render(){
        const { classes, title, submitAction, cancelAction, open } = this.props;
        const { camera } = this.state;
        return (
            <React.Fragment>
            <Dialog open={ open } onClose={() => cancelAction()}>
                <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название"
                        onChange={(e)=>{
                            this.setState({
                                camera: {
                                    ...this.state.camera,
                                    name: e.target.value
                                }
                            })}
                        }
                        value={ camera.name }
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Описание"
                        onChange={(e)=>{
                            this.setState({
                                camera: {
                                    ...this.state.camera,
                                    description: e.target.value
                                }
                            })}
                        }
                        value={ camera.description }
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="stream"
                        label="Основной поток"
                        onChange={(e)=>{
                            this.setState({
                                camera: {
                                    ...this.state.camera,
                                    stream: e.target.value
                                }
                            })}
                        }
                        value={ camera.stream }
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="substream"
                        label="Поток"
                        onChange={(e)=>{
                            this.setState({
                                camera: {
                                    ...this.state.camera,
                                    substream: e.target.value
                                }
                            })}
                        }
                        value={ camera.substream }
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={camera.public}
                                onChange={() => {this.setState({
                                    camera: {
                                        ...this.state.camera,
                                        public: !camera.public
                                    }
                                })}}
                                name="Public"
                            />
                        }
                        label="Public"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{ cancelAction() }} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={()=>{ submitAction(camera) }} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
        );
    }
};

const styles = theme => ({
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing.unit * 3
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});


export default withStyles(styles, { withTheme: true })(Form);
