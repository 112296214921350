import { compose, lifecycle, withState, withHandlers } from "recompose";
import { connect } from "react-redux";

import Users from "./Users";
import { getUsersList, addUser, editUser, deleteUser } from "../../store/actions/Users";
import { getPermissionsList } from "../../store/actions/Permissions";

export default compose(
  connect(
    state => ({
      isLoading: state.users.isLoading,
      users: state.users.list,
      error: state.users.error,
      permissions: state.permissions.permissions,
      devices: state.permissions.devices,
      permissionsAuthUser: state.auth.permissions.map(permission => permission.name)
    }),{
      getUsersList, addUser, getPermissionsList, editUser, deleteUser
    }
  ),
  withState("openForm", "setOpenForm", false),
  withState ("openConfirmationDialog", "setOpenConfirmationDialog", false),
  withState ("selectedUserId", "setSelectedUserId", -1),
  lifecycle({
    componentDidMount() {
      this.props.getUsersList();
      this.props.getPermissionsList();
    }
  }),
)(Users);
