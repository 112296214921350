export const GET_DEVICES_GROUP_LIST_REQUEST = "DevicesGroups/GET_DEVICES_GROUP_LIST_REQUEST";
export const GET_DEVICES_GROUP_LIST_SUCCESS = "DevicesGroups/GET_DEVICES_GROUP_LIST_SUCCESS";
export const GET_DEVICES_GROUP_LIST_FAILURE = "DevicesGroups/GET_DEVICES_GROUP_LIST_FAILURE";

export const ADD_DEVICES_GROUP_REQUEST = "DevicesGroups/ADD_DEVICES_GROUP_REQUEST";
export const ADD_DEVICES_GROUP_SUCCESS = "DevicesGroups/ADD_DEVICES_GROUP_SUCCESS";
export const ADD_DEVICES_GROUP_FAILURE = "DevicesGroups/ADD_DEVICES_GROUP_FAILURE";

export const UPDATE_DEVICES_GROUP_REQUEST = "DevicesGroups/UPDATE_DEVICES_GROUP_REQUEST";
export const UPDATE_DEVICES_GROUP_SUCCESS = "DevicesGroups/UPDATE_DEVICES_GROUP_SUCCESS";
export const UPDATE_DEVICES_GROUP_FAILURE = "DevicesGroups/UPDATE_DEVICES_GROUP_FAILURE";

export const DELETE_DEVICES_GROUP_REQUEST = "DevicesGroups/DELETE_DEVICES_GROUP_REQUEST";
export const DELETE_DEVICES_GROUP_SUCCESS = "DevicesGroups/DELETE_DEVICES_GROUP_SUCCESS";
export const DELETE_DEVICES_GROUP_FAILURE = "DevicesGroups/DELETE_DEVICES_GROUP_FAILURE";