import { compose, withState, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LoginView from "./LoginView";
import { loginUser } from "../../store/actions/Auth";

export default compose(
  connect(
    state => ({
      isLoading: state.auth.isLoading,
      isAuthenticated: state.auth.isAuthenticated,
      error: state.auth.error
    }),
    { loginUser }
  ),
  withRouter,
  withState("emailValue", "setEmailValue", ""),
  withState("passwordValue", "setPasswordValue", ""),
  withHandlers({
    handleInput: props => (e, input = "login") => {
      
      if (input === "email") {
        props.setEmailValue(e.target.value);
      } else if (input === "password") {
        props.setPasswordValue(e.target.value);
      }
    },
    handleLoginButtonClick: props => () => {
      props.loginUser(props.emailValue, props.passwordValue);
    }
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (!this.props.error && nextProps.error) {
        this.props.setPasswordValue("");
      }
    }
  })
)(LoginView);
