import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { toggleSidebar } from '../../store/reducers/Layout';

import LayoutView from './LayoutView';


export default compose(
  connect(
    state => ({
      isSidebarOpened: state.layout.isSidebarOpened,
      isAuthenticated: state.auth.isAuthenticated,
      Permissions: state.auth.permissions.map(permission => permission.name)
    }),
    { toggleSidebar },
  )
)(LayoutView);