import React from 'react';
import { Grid, Paper, withStyles, CircularProgress} from '@material-ui/core';


const CircularPreloader = ({ classes }) => (
  <Grid container className={classes.container}>
    <div className={classes.formContainer}>
          <React.Fragment>
            <CircularProgress size={80} className={classes.loader} />
          </React.Fragment>
    </div>
  </Grid>
);

const styles = theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  loader: {
    marginLeft: theme.spacing.unit * 4
  }
});

export default withStyles(styles, { withTheme: true })(CircularPreloader);