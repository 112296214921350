import React from "react";
import {
  withStyles,
  LinearProgress,
  AppBar,
  Tabs,
  Tab,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PageTitle from "../../components/PageTitle";
import ConformationDialog from '../../components/Common/ConformationDialog';
import TabPanel from '../../components/Common/TabPanel';
import UnitForm from "../../components/Directory/UnitForm";
import DevicesGroupForm from "../../components/Directory/DevicesGroupForm";

class Directory extends React.Component {
    constructor(props) {
      super(props);
    }

    handleOpenForm(type, item){
      if(this.props.tabIndex===1){
        this.props.setCurrentUnit(item);
        this.props.setUnitFormType(type);
        this.props.setOpenUnitForm(true);
      }
      if(this.props.tabIndex===0){
        this.props.setCurrentDevicesGroup(item);
        this.props.setDevicesGroupFormType(type);
        this.props.setOpenDevicesGroupForm(true);
      }
    }


    render(){
      const { classes, permissions, 
        openUnitForm, unitFormType, units, currentUnit, isLoadingUnits,
        openDevicesGroupForm, devicesGroupFormType, groups, currentDevicesGroup, isLoadingDevicesGroups,
      } = this.props;
  return (
    <React.Fragment>
      {(permissions.indexOf('devices-create') !== -1)?
        <PageTitle isLoading={isLoadingUnits} title="Справочники" button="Добавить" buttononClick={() => {this.handleOpenForm('add', null)}} />
        : <PageTitle isLoading={isLoadingUnits} title="Справочники" />
      }
      <AppBar position="static" color="default">
        <Tabs
          value={this.props.tabIndex}
          onChange={(event, newValue) => {
            this.props.setTabIndex(newValue);}
          }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="Справочники"
        >
          <Tab label="Группы устройств" id={0} onClick={()=>{ this.props.getDevicesGroupList() }} />
          <Tab label="Единицы измерения" id={1} onClick={()=>{ this.props.getUnitsList(); }} />
        </Tabs>
      </AppBar>
      <Paper>
        <TabPanel value={this.props.tabIndex} index={0} >
          {isLoadingDevicesGroups &&
              <LinearProgress style={{margin: "-24px"}}/>
          }
          { groups &&
            <List
              component="nav"
              aria-labelledby="groups"
              style={{margin: "-24px"}}
            >
              {groups.map(group => 
                <div>
                  <ListItem button>
                    <ListItemText primary={group.name} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" onClick={()=>{ this.handleOpenForm('edit', group) }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" 
                        onClick={()=> {
                          this.props.setDeletedType('group');
                          this.props.setDeletedItemId(group.id);
                          this.props.setOpenConfirmationDialog(true); 
                          }
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
                )
              }
            </List>
          }
        </TabPanel>
        <TabPanel value={this.props.tabIndex} index={1} >
          {isLoadingUnits &&
              <LinearProgress style={{margin: "-24px"}}/>
          }
          { units &&
            <List
              component="nav"
              aria-labelledby="units"
              style={{margin: "-24px"}}
            >
              {units.map(unit => 
                <div>
                  <ListItem button>
                    <ListItemText primary={unit.name+" ("+unit.short+")"} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" onClick={()=>{ this.handleOpenForm('edit', unit) }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" 
                        onClick={()=> {
                          this.props.setDeletedType('unit');
                          this.props.setDeletedItemId(unit.id);
                          this.props.setOpenConfirmationDialog(true); 
                          }
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
                )
              }
            </List>
          }
          
        </TabPanel>
      </Paper>

      {
        //форма ед измер
      }
      <UnitForm
        openForm={openUnitForm}
        nameForm={ (unitFormType==='edit') ? "Изменить" : "Добавить"}
        submitAction={(unit) => {(unitFormType==='edit') ? this.props.editUnit(unit) : this.props.addUnit(unit) }}
        cancelAction={() => {this.props.setOpenUnitForm(false)}}
        unit={currentUnit}
      />
      {
        //форма групп устройств
      }
      <DevicesGroupForm
        openForm={openDevicesGroupForm}
        nameForm={ (devicesGroupFormType==='edit') ? "Изменить" : "Добавить"}
        submitAction={(group) => {(devicesGroupFormType==='edit') ? this.props.editDevicesGroup(group) : this.props.addDevicesGroup(group) }}
        cancelAction={() => {this.props.setOpenDevicesGroupForm(false)}}
        group={currentDevicesGroup}
      />
      
      {
        //удаление
      }
      <ConformationDialog
        message="Вы уверены что хотите удалить?"
        openForm={this.props.openConfirmationDialog}
        submitLabel="Да"
        cancelLabel="Нет"
        submitAction={() => {
          if(this.props.deletedType==='unit'){
            this.props.deleteUnit(this.props.deletedItemId)
            this.props.setOpenConfirmationDialog (false);
          }
          if(this.props.deletedType==='group'){
            this.props.deleteDevicesGroup(this.props.deletedItemId)
            this.props.setOpenConfirmationDialog (false);
          }
        }}
        cancelAction={() => {
          this.props.setOpenConfirmationDialog(false);
        }}
      />
    </React.Fragment>
  );
    }
};

const styles = theme => ({
});


export default withStyles(styles, { withTheme: true })(Directory);
