import React from "react";
import {
  withStyles,
  LinearProgress,
} from "@material-ui/core";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import CamerasList from "./list"
import CameraForm from "./form"

class Camera extends React.Component {
    
    render(){
      const { classes, isLoading, list, permissions, openForm, formType } = this.props;
      //console.log(list);
  return (
    <React.Fragment>
      {isLoading && 
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      }
      { list && 
        <CamerasList
          list = { list }
        />
      }

      <CameraForm
        open = {openForm}
        title = "Добавить"
        submitAction = { (camera) => { this.props.createCamera(camera); this.props.setOpenForm(false) }}
        cancelAction = {() => { this.props.setOpenForm(false) }}
      />

      <SpeedDial
        ariaLabel="actions"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={ () => {this.props.setOpenSpeedDial(false)} }
        onOpen={() => {this.props.setOpenSpeedDial(true)}}
        open={this.props.openSpeedDial}
        direction={'left'}
      >
        {(permissions.indexOf('cameras-create') !== -1) &&
          <SpeedDialAction
            key={"AddCamera"}
            icon={<AddIcon />}
            tooltipTitle={"Добавить"}
            onClick={() => {this.props.setOpenForm(true)}}
          />
        }
          <SpeedDialAction
            key={"Filter"}
            icon={<SearchIcon />}
            tooltipTitle={"Фильтр"}
            onClick={() => { }}
          />
        </SpeedDial>      
    </React.Fragment>
  );
    }
};

const styles = theme => ({
  loader: {
    width: '100vh', 
    marginLeft: -theme.spacing.unit * 3
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(5),
      right: theme.spacing(5),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(5),
      left: theme.spacing(5),
    },
  },
});


export default withStyles(styles, { withTheme: true })(Camera);
