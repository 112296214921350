import {compose, lifecycle, withState, withHandlers} from 'recompose';
//import { withRouter } from "react-router-dom";
import {connect} from 'react-redux';

import DevicesView from './DevicesDetal';
import {
  getDevice,
  updateDevice,
  addDevice,
  deleteDevice,
  updateDeviceParam,
  editNotifications,
  scatchUpload,
  sendCommand,
} from '../../store/actions/Devices';
import {getParamsData} from '../../store/actions/DevicesParams';
import { getDevicesGroupList } from "../../store/actions/DevicesGroups";
import { getUnitsList } from "../../store/actions/Units";

export default compose (
  connect (
    state => ({
      units: state.units.list,

      isLoadingDevice: state.devices.isLoading,
      device: state.devices.current,
      devicesParamsData: state.devicesParams,
      error: state.devices.error,
      deviceDetalParamsData: state.devices.deviceDetalParamsData,
      uploadingScatch: state.devices.uploadingScatch,
      isUploadScatch: state.devices.isUploadScatch,
      devicesGroups: (state.devices_groups.list===null)?[]:state.devices_groups.list,
      permissions: state.auth.permissions.map (permission => permission.name),
    }),
    {
      getDevice, updateDevice, addDevice, deleteDevice,
      updateDeviceParam, 
      editNotifications,
      sendCommand,
      getParamsData,
      scatchUpload,
      getDevicesGroupList, getUnitsList
    }
  ),
  //withState("isOpenDialogDetal", "setOpenDialogDetal", false),
  withState ('openEditParamForm', 'setOpenEditParamForm', false),
  withState ('openNotificationsForm', 'setOpenNotificationsForm', false),
  withState ('openCommandsForm', 'setOpenCommandsForm', false),
  withState ('openDeviceEditForm', 'setOpenDeviceEditForm', false),
  withState ('openConfirmationDialog', 'setOpenConfirmationDialog', false),
  withState ('selectedParam', 'setSelectedParam', {
    id: null,
    label: '',
    color: '',
    classIcon: '',
  }),
  withState ('fromDate', 'setFromDate', new Date ()),
  withState ('toDate', 'setToDate', new Date ()),
  withState ('limit', 'setLimit', 100),
  withState ('tab', 'setTab', 'chart'),
  /*  withRouter,*/
  withHandlers ({
    handleOpenDialog: props => id => {
      props.setOpenDialogDetal (!props.isOpenDialogDetal);
    },
    handleGetParamsData: props => () => {
      props.getParamsData (
        props.device.id,
        props.fromDate,
        props.toDate,
        props.limit
      );
    },
  }),
  lifecycle ({
    componentDidMount () {
      this.props.getDevice (parseInt (this.props.match.params.id, 10));
      this.props.getDevicesGroupList();
      this.props.getUnitsList();
    },
  })
) (DevicesView);
