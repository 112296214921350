import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_DEVICES_LIST_REQUEST,
  GET_DEVICES_LIST_SUCCESS,
  GET_DEVICES_LIST_FAILURE,
  DEVICE_REFRESH,
  UPDATE_DEVICE_PARAM_REQUEST,
  UPDATE_DEVICE_PARAM_SUCCESS,
  UPDATE_DEVICE_PARAM_FAILURE,
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  EDIT_NOTIFICATIONS_REQUEST,
  EDIT_NOTIFICATIONS_SUCCESS,
  EDIT_NOTIFICATIONS_FAILURE,
  UPLOAD_SCATCH_REQUEST,
  UPLOAD_SCATCH_SUCCESS,
  UPLOAD_SCATCH_FAILURE,
  SEND_COMMAND_REQUEST,
  SEND_COMMAND_SUCCESS,
  SEND_COMMAND_FAILURE,
  ADD_NOTIFICATIONS_REQUEST,
  ADD_NOTIFICATIONS_SUCCESS,
  ADD_NOTIFICATIONS_FAILURE
} from '../constants/Devices';

export const getDevice = (id) => dispatch => {
  dispatch({type: GET_DEVICE_REQUEST});
  axios.get(`/api/devices/`+id)
      .then(res => {
        dispatch({
          type: GET_DEVICE_SUCCESS,
          device: res.data.data[0],
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: GET_DEVICE_FAILURE });
    });
};

export const refreshDevice = (device) => dispatch => {
  dispatch({
    type: DEVICE_REFRESH,
    device: device
  });
}

export const getDevicesList = (filter = {groups: []}) => dispatch => {
  dispatch({type: GET_DEVICES_LIST_REQUEST});
  axios.get(`/api/devices`, {
        params: {
          groups: JSON.stringify(filter.groups)
        }
      })
      .then(res => {
        var devices = {};
        res.data.data.map( (device) => { 
            Object.assign(devices, {[device.id]: device})
          }
        );
        dispatch({
          type: GET_DEVICES_LIST_SUCCESS,
          devices: devices,
          total: res.data.total,
          page: res.data.current_page
        });
      }).catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({ type: GET_DEVICES_LIST_FAILURE });
        }
    });
};

export const updateDevice = (device) => dispatch => {
  dispatch({ type: UPDATE_DEVICE_REQUEST });
  axios.put('/api/devices/'+device.id, device)
      .then(res => {
        toast.success("Устройство изменено!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_DEVICE_SUCCESS });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        toast.error("Что-то пошло не так...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_DEVICE_FAILURE });
    });
};

export const updateDeviceParam = (deviceParam) => dispatch => {
  dispatch({ type: UPDATE_DEVICE_PARAM_REQUEST });
  axios.put('/api/params/'+deviceParam.id, deviceParam)
      .then(res => {
        toast.success("Парамметр изменен!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_DEVICE_PARAM_SUCCESS });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        toast.error("Что-то пошло не так...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_DEVICE_PARAM_FAILURE });
    });
};

export const scatchUpload = (file, deviceid) => dispatch => {
  dispatch({type: UPLOAD_SCATCH_REQUEST});
  const url = '/api/devices/'+deviceid+'/scatch';
  const formData = new FormData();
  formData.append('file',file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  axios.post(url, formData, config)
    .then(res => {
      toast.success("Файл загружен!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      dispatch({type: UPLOAD_SCATCH_SUCCESS });
    })
    .catch(function (error) {
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          toast.error("Произошла ошибка при загрузке файла", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch({type: UPLOAD_SCATCH_FAILURE});
    });
}

export const sendCommand = (deviceid, command) => dispatch => {
  dispatch({type: SEND_COMMAND_REQUEST});
  axios.post('/api/devices/'+deviceid+'/command', command)
      .then(res => {
        dispatch({type: SEND_COMMAND_SUCCESS});
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: SEND_COMMAND_FAILURE});
    });
}

export const deleteDevice = (id) => dispatch => {
  dispatch({ type: DELETE_DEVICE_REQUEST });
  axios.delete('/api/devices/'+id)
      .then(res => {
        //window.location.href = '/';
        dispatch({ type: DELETE_DEVICE_SUCCESS, id: id });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_DEVICE_FAILURE });
    });
};

export const addDevice = (device) => dispatch => {
  dispatch({ type: ADD_DEVICE_REQUEST });
  axios.post('/api/devices', device)
      .then(res => {
        toast.success("Устройство добавлено!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({
          type: ADD_DEVICE_SUCCESS,
          device: res.data.data[0]
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        toast.error("Что-то пошло не так...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: ADD_DEVICE_FAILURE });
    });
};

/*export const getDevicesDetal = (id) => dispatch => {
  dispatch({ type: GET_DEVICES_DETAL_REQUEST });
  axios.get(`/api/devices/`+id)
      .then(res => {
        dispatch({
          type: GET_DEVICES_DETAL_SUCCESS,
          paramsData: res.data.data[0]
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: GET_DEVICES_DETAL_FAILURE });
    });
};*/

export const editNotifications = (id_device, notifications) => dispatch => {
  dispatch({type: ADD_NOTIFICATIONS_REQUEST });
  axios.post('/api/devices/'+id_device+'/notifications', notifications)
      .then(res => {
        dispatch({type: ADD_NOTIFICATIONS_SUCCESS });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ADD_NOTIFICATIONS_FAILURE});
    });
};