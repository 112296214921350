import axios from 'axios';
import { toast } from 'react-toastify';
import {
    GET_CAMERA_REQUEST,
    GET_CAMERA_SUCCESS,
    GET_CAMERA_FAILURE,
    GET_CAMERA_LIST_REQUEST,
    GET_CAMERA_LIST_SUCCESS,
    GET_CAMERA_LIST_FAILURE,
    CREATE_CAMERA_REQUEST,
    CREATE_CAMERA_SUCCESS,
    CREATE_CAMERA_FAILURE,
    UPDATE_CAMERA_REQUEST,
    UPDATE_CAMERA_SUCCESS,
    UPDATE_CAMERA_FAILURE,
    DELETE_CAMERA_REQUEST,
    DELETE_CAMERA_SUCCESS,
    DELETE_CAMERA_FAILURE 
} from '../constants/Cameras';

export const getCamera = (id) => dispatch => {
  dispatch({type: GET_CAMERA_REQUEST});
  axios.get(`/api/cameras/`+id)
      .then(res => {
        dispatch({
          type: GET_CAMERA_SUCCESS,
          device: res.data.data[0],
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: GET_CAMERA_FAILURE });
    });
};

export const getCamerasList = (filter = {groups: []}) => dispatch => {
  dispatch({type: GET_CAMERA_LIST_REQUEST});
  axios.get(`/api/cameras`, {
        params: {
          groups: JSON.stringify(filter.groups)
        }
      })
      .then(res => {
        dispatch({
          type: GET_CAMERA_LIST_SUCCESS,
          list: res.data.data,
          total: res.data.total,
          page: res.data.current_page
        });
      }).catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({ type: GET_CAMERA_LIST_FAILURE });
        }
    });
};

export const updateCameras = (device) => dispatch => {
  dispatch({ type: UPDATE_CAMERA_REQUEST });
  axios.put('/api/cameras/'+device.id, device)
      .then(res => {
        toast.success("Устройство изменено!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_CAMERA_SUCCESS });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        toast.error("Что-то пошло не так...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: UPDATE_CAMERA_FAILURE });
    });
};

export const deleteCamera = (id) => dispatch => {
  dispatch({ type: DELETE_CAMERA_REQUEST });
  axios.delete('/api/cameras/'+id)
      .then(res => {
        //window.location.href = '/';
        dispatch({ type: DELETE_CAMERA_SUCCESS, id: id });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_CAMERA_FAILURE });
    });
};

export const createCamera = (device) => dispatch => {
  dispatch({ type: CREATE_CAMERA_REQUEST });
  axios.post('/api/cameras', device)
      .then(res => {
        toast.success("Устройство добавлено!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({
          type: CREATE_CAMERA_SUCCESS,
          item: res.data.data[0]
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        toast.error("Что-то пошло не так...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: CREATE_CAMERA_FAILURE });
    });
};