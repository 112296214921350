import axios from 'axios';
import {
  GET_PERMISSIONS_LIST_REQUEST,
  GET_PERMISSIONS_LIST_SUCCESS,
  GET_PERMISSIONS_LIST_FAILURE } from '../constants/Permissions'

export const getPermissionsList = () => dispatch => {
  dispatch({type: GET_PERMISSIONS_LIST_REQUEST});
  axios.get(`/api/permissions`)
      .then(res => {
        var permissions = {};
        res.data.permissions.map( (permission) => { 
            Object.assign(permissions, {[permission.id]: permission})
          }
        );
        var devices = {};
        res.data.devices.map( (device) => { 
            Object.assign(devices, {[device.id]: device.name})
          }
        );
        dispatch({type: GET_PERMISSIONS_LIST_SUCCESS, permissions: permissions, devices: devices});
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_PERMISSIONS_LIST_FAILURE});
    });
};