export const GET_CAMERA_REQUEST =    "Cameras/GET_CAMERA_REQUEST";
export const GET_CAMERA_SUCCESS =    "Cameras/GET_CAMERA_SUCCESS";
export const GET_CAMERA_FAILURE =    "Cameras/GET_CAMERA_FAILURE";

export const GET_CAMERA_LIST_REQUEST="Cameras/GET_CAMERA_LIST_REQUEST";
export const GET_CAMERA_LIST_SUCCESS="Cameras/GET_CAMERA_LIST_SUCCESS";
export const GET_CAMERA_LIST_FAILURE="Cameras/GET_CAMERA_LIST_FAILURE";

export const CREATE_CAMERA_REQUEST = "Cameras/CREATE_CAMERA_REQUEST";
export const CREATE_CAMERA_SUCCESS = "Cameras/CREATE_CAMERA_SUCCESS";
export const CREATE_CAMERA_FAILURE = "Cameras/CREATE_CAMERA_FAILURE";

export const UPDATE_CAMERA_REQUEST = "Cameras/UPDATE_CAMERA_REQUEST";
export const UPDATE_CAMERA_SUCCESS = "Cameras/UPDATE_CAMERA_SUCCESS";
export const UPDATE_CAMERA_FAILURE = "Cameras/UPDATE_CAMERA_FAILURE";

export const DELETE_CAMERA_REQUEST = "Cameras/DELETE_CAMERA_REQUEST";
export const DELETE_CAMERA_SUCCESS = "Cameras/DELETE_CAMERA_SUCCESS";
export const DELETE_CAMERA_FAILURE = "Cameras/DELETE_CAMERA_FAILURE";