import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";

import DevicesView from "./Devices";
import { getDevicesList, addDevice, refreshDevice } from "../../store/actions/Devices";
import { getDevicesGroupList } from "../../store/actions/DevicesGroups";
import { getUnitsList } from "../../store/actions/Units";


export default compose(
  connect(
    state => ({
      isLoading: state.devices.isLoading,
      devices: state.devices,
      devicesGroups: (state.devices_groups.list===null)?[]:state.devices_groups.list,
      units: state.units.list,
      error: state.devices.error,
      permissions: state.auth.permissions.map(permission => permission.name),
    }),{
      getDevicesList, addDevice, refreshDevice,
      getDevicesGroupList, getUnitsList
    }
  ),
  withState ('openSpeedDial', 'setOpenSpeedDial', false),
  withState ('filter', 'setFilter', (localStorage.getItem('deviceFilter')) ? JSON.parse(localStorage.getItem('deviceFilter')) : { groups: [0] } ),
  withState ('openFilterForm', 'setOpenFilterForm', false),
  lifecycle({
    componentDidMount() {
      this.props.getDevicesList(this.props.filter);
      this.props.getDevicesGroupList(this.props.filter);
      this.props.getUnitsList();
    }
  })
)(DevicesView);