import {
  GET_DEVICES_LIST_REQUEST,
  GET_DEVICES_LIST_SUCCESS,
  GET_DEVICES_LIST_FAILURE,
  DEVICE_REFRESH,
  UPDATE_DEVICE_PARAM_REQUEST,
  UPDATE_DEVICE_PARAM_SUCCESS,
  UPDATE_DEVICE_PARAM_FAILURE,
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  EDIT_NOTIFICATIONS_REQUEST,
  EDIT_NOTIFICATIONS_SUCCESS,
  EDIT_NOTIFICATIONS_FAILURE,
  UPLOAD_SCATCH_REQUEST,
  UPLOAD_SCATCH_SUCCESS,
  UPLOAD_SCATCH_FAILURE,
  SEND_COMMAND_REQUEST,
  SEND_COMMAND_SUCCESS,
  SEND_COMMAND_FAILURE,
} from '../constants/Devices';

window.io = require('socket.io-client');

export const initialState = {
  page: 1,
  list: [],
  current: null,
  total: 0,
  isLoading: false,
  error: null,
  uploadingScatch: false,
  isUploadScatch: false,
};

export default function DevicesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEVICES_LIST_SUCCESS:
      return {
        ...state,
        list: action.devices,
        total: action.total,
        page: action.page,
        isLoading: false,
        error: null,
      };
    case GET_DEVICES_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case GET_DEVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEVICE_SUCCESS:
      return {
        ...state,
        current: action.device,
        isLoading: false,
        error: null,
      };
    case GET_DEVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case DELETE_DEVICE_SUCCESS:
      var dev = {};
      Object.keys(state.list).map(id => {
        if (Number(id) !== Number(action.id))
          Object.assign(dev, {[id]: state.list[id]});
      });
      return {
        ...state,
        isLoading: false,
        list: dev,
      };
    case DELETE_DEVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DEVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: Object.assign(
          {},
          Object.assign(state.list, {[action.device.id]: action.device})
        ),
      };
    case DEVICE_REFRESH:
      return {
        ...state,
        list: Object.assign({}, state.list, {[action.device.id]: action.device})
      };
    case UPLOAD_SCATCH_REQUEST:
      return {
        ...state,
        uploadingScatch: true,
        isUploadScatch: false,
      };
    case UPLOAD_SCATCH_SUCCESS:
      return {
        ...state,
        uploadingScatch: false,
        isUploadScatch: true,
      };
    case UPLOAD_SCATCH_FAILURE:
      return {
        ...state,
        uploadingScatch: false,
        isUploadScatch: false,
      };
    case SEND_COMMAND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_COMMAND_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_COMMAND_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
