import React from "react";
import {
  Grid,
  withStyles,
  Paper,
  Tooltip
} from "@material-ui/core";
import { Typography } from "../Wrappers/Wrappers";
import { Link } from "react-router-dom";
import axios from 'axios';
import Echo from "laravel-echo"

window.io = require('socket.io-client');

class ListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    new Echo({
          broadcaster: 'pusher',
          key: `${process.env.REACT_APP_WS_KEY}`,
          cluster: `${process.env.REACT_APP_WS_CLUSTER}`,
          wsHost: `${process.env.REACT_APP_WS_HOST}`,
          wsPort: `${process.env.REACT_APP_WS_PORT}`,
          wssPort: `${process.env.REACT_APP_WSS_PORT}`,
          enabledTransports: ['ws', 'wss'],
          disableStats: true,
          encrypted: true,
          authEndpoint: `${process.env.REACT_APP_WS_AUTH_ENDPOINT_URL}`,
          auth: {
            headers: {
              Authorization: axios.defaults.headers.common['Authorization'],
              'Accept': 'application/json'
            }
          },
        }).private(`device.`+this.props.device.id)
              .listen('.DeviceEvent', (e) => {
                this.props.refresh(JSON.parse(e));
              })
  }
  componentWillUnmount(){
      //Echo.leave(`device.`+this.props.device.id);
  }

  render() {
    const { classes, theme, device, units, handleClickOpenDetalDialog } = this.props;
    return (  
      <Grid item lg={4} md={6} sm={6} xs={12}>
          <Paper className={classes.paper} key={'device_'+device.id}>
            
              <Grid container alignItems="center">
                <Grid item xs>
                  <Link to={"/devices/" + device.id} style={{ textDecoration: 'none', textAlign: 'center' }}>
                    <Typography variant="h4" style={{fontWeight: 600, color: '#4b5154'}}>{device.name}</Typography>
                  </Link>
                </Grid>
                <Tooltip title={ device.lastUpdate }> 
                  <Grid item>
                    <Typography variant="h6" color={device.status==='on'?'primary':'error'}>
                      {device.status}
                    </Typography>
                  </Grid>
                </Tooltip>
              </Grid>

            {Object.keys(device.params).length == 0 &&
              <Grid className={classes.ParamItemContainer} container justify="center" alignItems="center" spacing={2}>
                <Grid className={classes.ParamItem} justify="center" alignItems="center" item>
                  <Typography variant="caption" display="block" gutterBottom>Нет данных</Typography>
                </Grid>
              </Grid>
            }
            {Object.keys(device.params).length != 0 &&
              <Grid className={classes.card} container direction="row" spacing={1} lg={6} md={6} sm={6} xs={6}>
              {device.params.map(param => 
              ((!param.isHidden) && (param.name.indexOf("DS18B20_0")>-1 || param.name.indexOf("DHT_T")>-1 || param.name.indexOf("DHT_H")>-1 || param.name.indexOf("T_BMP280")>-1 || param.name.indexOf("P_BMP280")>-1 || param.name.indexOf("A_BMP280")>-1)) ?
              <Tooltip title={param.label}>
                <div className={classes.cardContent}>
                <Grid item direction="row">
                  <Typography variant="h3" display="inline" >
                      <i className={param.classIcon}></i>
                  </Typography>
                  <Typography variant="h6" display="inline">
                    { " "+param.value+" " }
                  </Typography>
                  <Typography variant="body2" display="inline" gutterBottom> { (!!units)?units.filter(unit => unit.id === param.id_unit)[0].short:"" } </Typography>
                </Grid>
                <Grid item direction="column" wrap="nowrap" justify="center" alignItems="center">
                  <Typography variant="caption" display="block" noWrap>
                    {param.label} 
                  </Typography>
                </Grid>                    
              </div>
              </Tooltip>
              : null
            )}
            </Grid>
            }
            {Object.keys(device.params).length != 0 &&
              <Grid className={classes.card} container direction="row" wrap="nowrap" spacing={1} item lg={6} md={6} sm={6} xs={12}>
              {device.params.map(param => 
              ((!param.isHidden) && (param.name.indexOf("DS18B20_0")===-1 && param.name.indexOf("DHT_T")===-1 && param.name.indexOf("DHT_H")===-1 && param.name.indexOf("T_BMP280")===-1 && param.name.indexOf("P_BMP280")===-1 && param.name.indexOf("A_BMP280")===-1)) ?
              <Tooltip title={param.label}>
              <div className={classes.cardContent}>
                <Grid item direction="row">
                  <Typography variant="h3" display="inline" >
                      <i className={param.classIcon}></i>
                  </Typography>
                  <Typography variant="h6" display="inline">
                    { " "+param.value+" " }
                  </Typography>
                  <Typography variant="body2" display="inline" gutterBottom> { (!!units)?units.filter(unit => unit.id === param.id_unit)[0].short:"" } </Typography>
                </Grid>
                <Grid item direction="column" wrap="nowrap" justify="center" alignItems="center">
                  <Typography variant="caption" display="block" noWrap>
                    {param.label} 
                  </Typography>
                </Grid>                    
              </div>
              </Tooltip>
              : null
            )}
            </Grid>
            }
            </Paper>
            </Grid>  
          

    )
        }
    }
    const styles = theme => ({
      Сhild: {
        height: `100%`
      },
        ParamItemContainer: {
            display: 'flex',
            minWidth: '100%',
            minHeight: 100,
            overflowX: 'auto',
            flexWrap: 'inherit',
          },
          ParamItem: {
            minWidth: 100,
            margin: '5px',
            padding: '5px',
          },
          root: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-around",
            padding: 0,
            backgroundColor: theme.palette.background.paper,
          },
          button: {
              margin: theme.spacing.unit,
          },
          card: {
            display: 'flex',
            minWidth: '100%',
            overflowX: 'auto'
          },
          cardContent: {
            width: '33%',
            maxWidth: '33%',
            minWidth: '33%',
            padding: '5px'
          },
          visitsNumberContainer: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            paddingBottom: theme.spacing.unit
          },
          paper: {
            padding: theme.spacing.unit * 2,
            margin: theme.spacing.unit,
            color: theme.palette.text.secondary,
          },
          progressSection: {
            marginBottom: theme.spacing.unit
          },
          progressTitle: {
            marginBottom: theme.spacing.unit * 2
          },
          pieChartLegendWrapper: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            marginRight: theme.spacing.unit
          },
          legendItemContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing.unit
          },
          fullHeightBody: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-between"
          },
          tableWidget: {
            overflowX: "auto"
          },
          progressBar: {
            backgroundColor: theme.palette.warning.main
          },
          performanceLegendWrapper: {
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            marginBottom: theme.spacing.unit
          },
          legendElement: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing.unit * 2,
          },
          legendElementText: {
            marginLeft: theme.spacing.unit
          },
          serverOverviewElement: {
            display: "flex",
            alignItems: "center",
            maxWidth: "100%"
          },
          serverOverviewElementText: {
            minWidth: 145,
            paddingRight: theme.spacing.unit * 2
          },
          serverOverviewElementChartWrapper: {
            width: "100%"
          },
          mainChartBody: {
            overflowX: 'auto',
          },
          mainChartHeader: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.only("xs")]: {
              flexWrap: 'wrap',
            }
          },
          mainChartHeaderLabels: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.only("xs")]: {
              order: 3,
              width: '100%',
              justifyContent: 'center',
              marginTop: theme.spacing.unit * 3,
              marginBottom: theme.spacing.unit * 2,
            }
          },
          mainChartHeaderLabel: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing.unit * 3,
          },
          mainChartSelectRoot: {
            borderColor: theme.palette.text.hint + '80 !important',
          },
          mainChartSelect: {
            padding: 10,
            paddingRight: 25
          },
          mainChartLegentElement: {
            fontSize: '18px !important',
            marginLeft: theme.spacing.unit,
          }
    });
  

    export default withStyles(styles, { withTheme: true })(ListItem);