export const GET_USERS_LIST_REQUEST = "Users/GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "Users/GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "Users/GET_USERS_LIST_FAILURE";

export const ADD_USER_REQUEST = "Users/ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "Users/ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "Users/ADD_USER_FAILURE";

export const DELETE_USER_REQUEST = "Users/DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "Users/DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "Users/DELETE_USER_FAILURE";

export const EDIT_USER_SUCCESS = "Users/EDIT_USER_SUCCESS";