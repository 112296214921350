import axios from 'axios';

import {
    GET_DEVICES_GROUP_LIST_REQUEST,
    GET_DEVICES_GROUP_LIST_SUCCESS,
    GET_DEVICES_GROUP_LIST_FAILURE,
    ADD_DEVICES_GROUP_REQUEST,
    ADD_DEVICES_GROUP_SUCCESS,
    ADD_DEVICES_GROUP_FAILURE,
    UPDATE_DEVICES_GROUP_REQUEST,
    UPDATE_DEVICES_GROUP_SUCCESS,
    UPDATE_DEVICES_GROUP_FAILURE,
    DELETE_DEVICES_GROUP_REQUEST,
    DELETE_DEVICES_GROUP_SUCCESS,
    DELETE_DEVICES_GROUP_FAILURE
} from '../constants/DevicesGroups';
import { toast } from 'react-toastify';


export const getDevicesGroupList = () => dispatch => {
  dispatch({type: GET_DEVICES_GROUP_LIST_REQUEST});
  axios.get(`/api/devicesgroups`)
      .then(res => {
        dispatch({
          type: GET_DEVICES_GROUP_LIST_SUCCESS,
          list: res.data.data,
          page: res.data.current_page,
          total_pages: res.data.total,
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_DEVICES_GROUP_LIST_FAILURE});
    });
};

export const addDevicesGroup = (group) => dispatch => {
  dispatch({type: ADD_DEVICES_GROUP_REQUEST});
  axios.post('/api/devicesgroups', group)
      .then(res => {
        toast.success("Группа добавлена!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({type: ADD_DEVICES_GROUP_SUCCESS, group: res.data});
      }).catch(function (error) {
        toast.error("Произошла ошибка при добавлении", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ADD_DEVICES_GROUP_FAILURE});
    });
};

export const editDevicesGroup = (group) => dispatch => {
  dispatch({type: UPDATE_DEVICES_GROUP_REQUEST});
  axios.put('/api/devicesgroups/'+group.id, group)
      .then(res => {
        toast.success("Группа изменена!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({type: UPDATE_DEVICES_GROUP_SUCCESS, group: res.data});
      }).catch(function (error) {
        toast.error("Произошла ошибка при редактировании", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: UPDATE_DEVICES_GROUP_FAILURE});
    });
};

export const deleteDevicesGroup = (id) => dispatch => {
  dispatch({ type: DELETE_DEVICES_GROUP_REQUEST });
  axios.delete('/api/devicesgroups/'+id)
      .then(res => {
        toast.success("Группа удалена!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch({ type: DELETE_DEVICES_GROUP_SUCCESS, id: id });
      }).catch(function (error) {
        toast.error("Произошла ошибка при удалении", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_DEVICES_GROUP_FAILURE });
    });
};