import axios from 'axios';
import {
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS
} from '../constants/Users';

export const initialState = {
  page: 1,
  list: [],
  total: 0,
  isLoading: false,
  error: null
};

export default function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        list: action.data.data,
        total: action.data.total,
        isLoading: false
      };
    case GET_USERS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_USERS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case ADD_USER_SUCCESS:
      state.list.push(action.user);
      return {
        ...state,
        list: state.list,
        isLoading: false
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        list: state.list.map( user => (user.id == action.user.id)?action.user:user ),
        isLoading: false
      };
    case DELETE_USER_SUCCESS:
      var dev = [];
      state.list.map(user => {
        if (Number(user.id) !== Number(action.id))
          dev.push(user);
      });
      return {
        ...state,
        isLoading: false,
        list: dev,
      };
    default:
      return state;
  }
}
